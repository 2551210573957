import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { CompanyRole, EnumProjectRequestStatus } from 'tdc-web-backend/enums/enums';
import useTable from '../../../components/table/useTable';

import TableWithChipFilters from '../../../components/table/TableWithChipFilters';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import { formatDateFns } from '../../../utils/helpers';

const getCompanyType = (params: any) =>
  params.row.company.roles.includes(CompanyRole.Buyer) ? 'Buyer' : 'Seller';

const ProjectRequests = () => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'projectName',
      headerName: 'Project name',
      editable: false,
      flex: 0.3,
    },
    {
      field: 'company',
      headerName: 'Company requested',
      editable: false,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 600,
          }}
        >
          {params.value.name}
        </Typography>
      ),
    },
    {
      field: 'companyRole',
      headerName: 'Company type',
      editable: false,
      flex: 0.2,
      valueGetter: getCompanyType,
    },

    {
      field: 'created',
      headerName: 'Requested',
      editable: false,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>{formatDateFns(params.value, '-')}</Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => {
        let status = params.value;
        if (status === EnumProjectRequestStatus.Requested && params.row.project !== null) {
          status = EnumProjectRequestStatus.ProjectCreated;
        }
        return <StatusChipField text={status} />;
      },
    },
  ];

  const { resourceLimit, chipFilters, ...props } = useTable({
    resourceUrl: 'project-requests',
    withTabs: true,
    chipFilterQueryParams: [
      {
        title: 'All',
      },
      //   {
      //     title: 'published',
      //     search: { status: EnumBlogStatus.Published },
      //   },
      //   {
      //     title: 'draft',
      //     search: { status: EnumBlogStatus.Draft },
      //   },
    ],
  });

  return (
    <TableWithChipFilters
      numberOfRowsPerPage={resourceLimit}
      columns={columns}
      onRowClick={(e) => navigate(`./${e.row.id}`)}
      withElements={{
        withTitle: 'Project requests (E2E)',
      }}
      chipFilters={
        chipFilters as { label: string; isLoading: boolean; count: number | undefined }[]
      }
      sx={{
        '& .MuiDataGrid-cell': {
          overflowX: 'auto',
        },
      }}
      {...props}
    />
  );
};

export default ProjectRequests;
