/* eslint-disable import/no-unresolved */
import { Typography, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Cancel, Check } from '@mui/icons-material';
import { FeaturedCompaniesDto } from 'tdc-web-backend/featured-companies/schema';
import CustomTable from '../../../components/custom-table/CustomTable';
import { truncate } from '../../../utils/helpers';

const CaseStudyArchiveList = () => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: 'primaryDark.600' }}>
          {truncate(params.value, 40)}
        </Typography>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: 'primaryDark.600' }}>
          {truncate(params.value, 100)}
        </Typography>
      ),
    },
    {
      field: 'id',
      headerName: 'Companies',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="bodySemiBold1" sx={{ color: 'primaryDark.600' }}>
          {params.row?.caseStudies?.length}
        </Typography>
      ),
    },
    {
      field: 'main',
      headerName: 'Main',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (params.value ? <Check /> : <Cancel />),
    },
  ];

  return (
    <Stack direction="column" sx={{ height: 'fit-content', width: '100%' }} spacing={2}>
      <CustomTable<FeaturedCompaniesDto>
        resource="featured-case-studies"
        columns={columns}
        disableTabView
        onRowClick={(params) => navigate(`./${params.id}`)}
        autoHeight
        hideFooter
      />
    </Stack>
  );
};

export default CaseStudyArchiveList;
