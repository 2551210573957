import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { EnumBlogStatus } from 'tdc-web-backend/enums/enums';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import useTable from '../../../components/table/useTable';
import CustomButton from '../../../components/button/CustomButton';
import TableWithChipFilters from '../../../components/table/TableWithChipFilters';
import { formatDateFns } from '../../../utils/helpers';
import NewsletterActionMenu from './components/NewsletterActionMenu';

const Newsletter = () => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 0.3,
    },
    {
      field: 'created',
      headerName: 'Created',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>{formatDateFns(params.value)}</Typography>
      ),
    },
    {
      field: 'updated',
      headerName: 'Updated',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>{formatDateFns(params.value)}</Typography>
      ),
    },
    {
      field: 'unsubcribed',
      headerName: 'Subscribed',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.unsubscribed ? <CloseIcon /> : <CheckIcon />,
    },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   flex: 0.2,
    //   renderCell: (params: GridRenderCellParams) => <NewsletterActionMenu params={params} />,
    // },
  ];

  const { resourceLimit, chipFilters, ...props } = useTable({
    resourceUrl: 'newsletter',
    withTabs: true,
    chipFilterQueryParams: [
      {
        title: 'All',
      },
      {
        title: 'Subscribed',
        search: { unsubscribed: 'false' },
      },
      {
        title: 'Unsubscribed',
        search: { unsubscribed: 'true' },
      },
    ],
  });

  return (
    <TableWithChipFilters
      numberOfRowsPerPage={resourceLimit}
      columns={columns}
      onRowClick={(e) => {}}
      withElements={{
        withTitle: 'Subscribers',
      }}
      chipFilters={
        chipFilters as { label: string; isLoading: boolean; count: number | undefined }[]
      }
      sx={{
        '& .MuiDataGrid-cell': {
          overflowX: 'auto',
        },
      }}
      {...props}
    />
  );
};

export default Newsletter;
