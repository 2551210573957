import { Typography, Stack, IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { EnumTimedProjectPitchStatus } from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { formatDateFns, truncate } from '../../../utils/helpers';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import useTable from '../../../components/table/useTable';
import BaseTable from '../../../components/table/BaseTable';
import CustomButton from '../../../components/button/CustomButton';

const PitchingList = () => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => <StatusChipField text={params.value} />,
    },
    {
      field: 'projectName',
      headerName: 'Project Name',
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: 'primaryDark.600' }}>
          {truncate(params.value, 40)}
        </Typography>
      ),
    },
    {
      field: 'published',
      headerName: 'Published',
      flex: 0.15,
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{formatDateFns(params.value)}</Typography>
      ),
    },
    {
      field: 'edit',
      headerName: 'Approved',
      flex: 0.05,
      renderCell: (params: GridRenderCellParams) => {
        const showPending =
          params.row.status === EnumTimedProjectPitchStatus.Pending || params.row.edit;
        return (
          <StatusChipField
            text={
              showPending
                ? EnumTimedProjectPitchStatus.Pending
                : params.row.status === EnumTimedProjectPitchStatus.Draft
                ? EnumTimedProjectPitchStatus.Draft
                : 'approved'
            }
            componentReturnType="dot"
          />
        );
      },
    },
    {
      field: 'editIcon',
      headerName: '',
      flex: 0.03,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            navigate(`./${params.row.id}/edit`);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const panelConfig = [
    {
      title: 'All',
    },
    {
      title: 'Pending',
      search: { status: EnumTimedProjectPitchStatus.Pending },
    },
    {
      title: 'Chosen',
      search: { status: EnumTimedProjectPitchStatus.Chosen },
    },
    {
      title: 'Hired',
      search: { status: EnumTimedProjectPitchStatus.Hired },
    },
    {
      title: '1st Round',
      search: { status: EnumTimedProjectPitchStatus.FirstRound },
    },
    {
      title: '1st Round Rejected',
      search: { status: EnumTimedProjectPitchStatus.FirstRoundRejected },
    },
    {
      title: '2nd Round',
      search: { status: EnumTimedProjectPitchStatus.SecondRound },
    },
    {
      title: '2nd Round Rejected',
      search: { status: EnumTimedProjectPitchStatus.SecondRoundRejected },
    },
  ];

  const { resourceLimit, ...props } = useTable({
    resourceUrl: 'timed-project-pitches',
  });

  return (
    <Stack sx={{ height: 'fit-content', width: '100%' }} spacing={2}>
      <BaseTable
        disableSelectionOnClick
        numberOfRowsPerPage={resourceLimit}
        columns={columns}
        onRowClick={(params) =>
          params.row.edit ? navigate(`./${params.row.edit}`) : navigate(`./${params.row.content}`)
        }
        withElements={{
          withTitle: 'Pitches (tabs & filters will be added)',
          withCta: <CustomButton onClick={() => navigate('./create')}>Create pitch</CustomButton>,
        }}
        {...props}
      />
    </Stack>
  );
};

export default PitchingList;
