import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { EnumScreenList, EnumTimedProjectStatus } from 'tdc-web-backend/enums/enums';
import TimeProjectsListCards from './TimedProjectsList/TimeProjectsListCards';
import CustomTable from '../../../components/custom-table/CustomTable';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import { formatDateFns } from '../../../utils/helpers';
import BannerNotificationMapper from '../../../components/notifications/bannerNotifications/BannerNotificationMapper';
import useAuth from '../../../utils/hooks/useAuth';

const TimedProjects = () => {
  const navigate = useNavigate();
  const {
    authData: { userData: currentUser },
  } = useAuth();

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => <StatusChipField text={params.value} />,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" fontWeight={600} sx={{ color: 'primaryDark.600' }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'published',
      headerName: 'Published',
      flex: 0.15,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2">{formatDateFns(params.value, '/')}</Typography>
      ),
    },
    {
      field: 'closed',
      headerName: 'Closed',
      flex: 0.15,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2">{formatDateFns(params.value, '/')}</Typography>
      ),
    },
    {
      field: 'pitchCount',
      headerName: 'Pitches',
      flex: 0.15,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2">{params.value}</Typography>
      ),
    },
  ];

  const panelConfig = [
    {
      id: 'timed-projects_all',
      title: 'All',
    },
    {
      id: 'timed-projects_active',
      title: 'Active',
      search: { status: EnumTimedProjectStatus.Active },
    },
    {
      id: 'timed-projects_closed',
      title: 'Closed',
      search: { status: EnumTimedProjectStatus.Closed },
    },
    {
      id: 'timed-projects_pending',
      title: 'Pending',
      search: { status: EnumTimedProjectStatus.Pending },
    },
    {
      id: 'timed-projects_draft',
      title: 'Draft',
      search: { status: EnumTimedProjectStatus.Draft },
    },
  ];

  return (
    <Stack direction="column" spacing={4} height="100%">
      <BannerNotificationMapper screen={EnumScreenList.TimedProjectScreen} />
      <TimeProjectsListCards />
      <CustomTable
        resource="timed-projects"
        searchParams={{
          company: currentUser?.membership?.company,
        }}
        columns={columns}
        panelConfig={panelConfig}
        onRowClick={(params) => navigate(`./${params.id}`)}
        autoHeight
        hideFooter
      />
    </Stack>
  );
};

export default TimedProjects;
