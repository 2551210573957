import { FormProvider, useForm } from 'react-hook-form';
import CustomInput from '../../../../components/custom-input/CustomInput';
import { Stack } from '@mui/material';
import CustomButton from '../../../../components/button/CustomButton';

const Autocomplete = () => {
  const formMethods = useForm({
    defaultValues: {
      //   skills: 'id-ca',
      //   skills: { id: 'id-in', name: 'InVision' },
      //   skills: [
      //     { id: 'id-in', name: 'InVision' },
      //     { id: 'id-ca', name: 'Canva' },
      //   ],
      skills: ['id-in', 'id-ca'],
      // MAP
      //   skills: 'in',
      //   skills: ['in', 'ca'],
    },
  });
  const techToolsArray = [
    { id: 'id-ap', name: 'Adobe Photoshop' },
    { id: 'id-ai', name: 'Adobe Illustrator' },
    { id: 'id-fi', name: 'Figma' },
    { id: 'id-sk', name: 'Sketch' },
    { id: 'id-in', name: 'InVision' },
    { id: 'id-ax', name: 'Adobe XD' },
    { id: 'id-cd', name: 'CorelDRAW' },
    { id: 'id-ca', name: 'Canva' },
    { id: 'id-ad', name: 'Affinity Designer' },
    { id: 'id-gmp', name: 'GIMP' },
  ];

  const techToolsMap = new Map([
    ['ap', 'Adobe Photoshop'],
    ['ai', 'Adobe Illustrator'],
    ['fi', 'Figma'],
    ['sk', 'Sketch'],
    ['in', 'InVision'],
    ['ax', 'Adobe XD'],
    ['cd', 'CorelDRAW'],
    ['ca', 'Canva'],
    ['ad', 'Affinity Designer'],
    ['gmp', 'GIMP'],
  ]);

  return (
    <FormProvider {...formMethods}>
      <Stack spacing={5} gap={5} width="600px">
        <CustomInput
          input={<CustomInput.Autocomplete />}
          sx={{ mt: 3, flex: 1 }}
          label="tech tools"
          placeholder="Select preferred software or tools"
          name="skills"
          choices={techToolsArray}
          //   choices={techToolsMap}
          valueKey="id"
          labelKey="name"
          //   keepOnlyValueKeys={false}
          multiple
          withChips
          //   fullWidth
        />
        <CustomButton onClick={() => console.log(formMethods.getValues())}>Log</CustomButton>
      </Stack>
    </FormProvider>
  );
};
export default Autocomplete;
