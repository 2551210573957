import Typography from '@mui/material/Typography';
import { Divider, IconButton, Stack, SxProps, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/project-icons/ArrowRightIcon.svg';
import { primaryLight, secondaryBlue } from '../../utils/color';
import { LAYOUT_DEFAULTS } from '../../utils/constants';
import useAuth from '../../utils/hooks/useAuth';

interface BecomeAPartOfSponaProps {
  title: string;
  subtitle?: string;
  bgcolor?: string;
  color?: string;
  dividerColor?: string;
  borderColor?: string;
  boxShadow?: boolean;
  buttonBgColor?: string;
  onButtonClick?: () => void;
  sx?: SxProps<Theme>;
}

const BecomeAPartOfSpona = ({
  title,
  subtitle = 'Become a part of Spona to get started on your next big project.',
  bgcolor = primaryLight[500],
  color = 'white',
  dividerColor = secondaryBlue[100],
  borderColor,
  boxShadow = true,
  buttonBgColor = 'white',
  onButtonClick,
  sx,
}: BecomeAPartOfSponaProps) => {
  const navigate = useNavigate();
  const {
    authData: { accessToken },
  } = useAuth();
  const hasUser = accessToken !== null;

  return (
    <Stack alignItems="center" sx={{ ...LAYOUT_DEFAULTS, pb: '12px', ...sx }}>
      <Stack
        spacing={2}
        sx={{
          bgcolor,
          width: '100%',
          borderRadius: '20px',
          border: borderColor ? `1px solid ${borderColor}` : 'none',
          boxShadow: boxShadow ? '6px 6px 15px 0px rgba(56, 69, 114, 0.10)' : 'none',
          p: {
            xs: 3,
            md: 6,
          },
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}
            color={color}
            width={{ xs: '60%', sm: '60%', md: '100%' }}
          >
            {title}
          </Typography>

          <IconButton
            onClick={() =>
              onButtonClick
                ? onButtonClick?.()
                : hasUser
                ? navigate('/dashboard/end-to-end')
                : navigate('/register')
            }
            sx={{
              backgroundColor: buttonBgColor,
              boxShadow: '6px 6px 15px 0px rgba(56, 69, 114, 0.10)',
              height: { xs: '2rem', lg: '3.5rem' },
              width: { xs: '2rem', lg: '3.5rem' },
              '&:hover': {
                bgcolor: 'primaryDark.150',
              },
            }}
          >
            <ArrowRightIcon fill={secondaryBlue[500]} width="1rem" height="1rem" />
          </IconButton>
        </Stack>

        <Divider sx={{ borderColor: dividerColor }} />

        <Typography
          sx={{ typography: { xs: 'publicBody3Light', lg: 'publicBody3Regular' } }}
          color={color}
        >
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BecomeAPartOfSpona;
