import { Stack, SxProps, Theme, Typography } from '@mui/material';

const HomepPageList = ({ items, sx }: { items: string[]; sx?: SxProps<Theme> }) => (
  <Stack pl={1} sx={{ ...sx }}>
    {items.map((item, index) => (
      <Stack key={index} spacing={1} direction="row" alignItems="start">
        <Typography color="secondaryGray.600" variant="publicBody5Regular">
          •
        </Typography>
        <Typography color="secondaryGray.600" variant="publicBody5Regular">
          {item}
        </Typography>
      </Stack>
    ))}
  </Stack>
);
export default HomepPageList;
