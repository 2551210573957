import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import { secondaryOrange } from '../../../../utils/color';
import { LAYOUT_DEFAULTS } from '../../../../utils/constants';
import { ReactComponent as PersonCheck } from '../../../../assets/icons/person-check-fill.svg';
import { ReactComponent as ThreeUsers } from '../../../../assets/icons/three-users.svg';
import SponaCircle from '../../../../assets/img/public-pages/spona-circle.png';
import { ReactComponent as Vector } from '../../../../assets/icons/vector.svg';
// import { ReactComponent as Trophy } from '../../../../assets/icons/trophy.svg';
import { ReactComponent as Trophy } from '../../../../assets/icons/trophy2.svg';
// import { ReactComponent as Trophy } from '../../../../assets/icons/layout-icons/trophy2-fill.svg';
// import { ReactComponent as Trophy } from '../../../../assets/icons/notifications/AchievementTrophyIcon.svg';
import SolutionItem from './components/SolutionItem';

const FourSolutions = () => (
  <Stack
    width="100%"
    bgcolor={secondaryOrange[101]}
    sx={{
      position: 'relative',
      py: { xs: '25%', sm: '8.75rem' },
    }}
  >
    <Box
      component="img"
      src={SponaCircle}
      sx={{
        position: 'absolute',
        top: '0',
        right: '0',
        width: '30%',
        // height: '30%',
        zIndex: 999,
        // transform: 'translate(-50%, -50%)',
        maxWidth: '600px',
      }}
    />
    {/* <Stack spacing={10} sx={{ ...LAYOUT_DEFAULTS, mx: '8.75rem' }}> */}
    <Stack spacing={10} sx={{ ...LAYOUT_DEFAULTS }}>
      <Stack spacing={1}>
        <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
          Our Four Core Solutions
        </Typography>
      </Stack>

      <Stack rowGap={15} columnGap="150px" direction="row" flexWrap="wrap">
        <SolutionItem
          Icon={PersonCheck}
          title="Hire"
          content="Access a global network of vetted professionals across design, tech, marketing, and more. Post your project, review proposals, and hire with confidence—Spona manages logistics and fees so you can focus on results."
          contentSx={{
            minHeight: {
              md: '140px',
              bg: '120px',
              gr: '100px',
              xl: '70px',
              xxl: '100px',
            },
          }}
          listItems={[
            'Vetted professionals in various fields',
            'Secure and transparent fee structure',
            'Built-in collaboration tools',
          ]}
          listItemsSx={{
            minHeight: {
              md: '108px',
              bg: '0',
            },
          }}
          buttonText="Hire Now"
          buttonDestination="/seller-archive"
        />
        <SolutionItem
          Icon={ThreeUsers}
          title="Offer Services"
          content="Are you a freelancer or agency? Offer your services to a global marketplace that values expertise and transparency. Let Spona handle client payments, contracts, and communication, so you can focus on delivering great work."
          contentSx={{
            minHeight: {
              md: '140px',
              bg: '120px',
              gr: '100px',
              xl: '70px',
              xxl: '100px',
            },
          }}
          listItems={[
            'Easy onboarding for freelancers/agencies',
            'Built-in contract and payment workflows',
            'Access to a broad client base',
          ]}
          listItemsSx={{
            minHeight: {
              md: '108px',
              bg: '0',
            },
          }}
          buttonText="Start Offering Now"
          buttonDestination="/register"
        />
        <SolutionItem
          Icon={Vector}
          title="Management Tool"
          content="Plan tasks, track milestones, manage payments, and collaborate in real-time—all in one place. Whether you’re starting fresh or bringing in ongoing projects, our  management tool ties seamlessly into your Hire, Services process and Spona Performance campaigns, so nothing slips through the cracks."
          contentSx={{
            minHeight: {
              md: '170px',
              bg: '160px',
              gr: '120px',
            },
          }}
          listItems={[
            'KPI-based contracts and performance tracking',
            'Automated invoicing and payments',
            'Centralized messaging and file sharing',
          ]}
          listItemsSx={{
            minHeight: {
              md: '162px',
              bg: '108px',
              gr: '0',
            },
          }}
          buttonText="Try our Tool"
          buttonDestination="/how-to-manage-buyer"
        />
        <SolutionItem
          Icon={Trophy}
          title="Spona Performance - Marketing & Consulting"
          content="Take your marketing to the next level with expert guidance, tiered commissions, and KPI-driven campaigns. Best of all, our E2E Tool keeps your marketing milestones, budgets, and reporting fully integrated—so you can optimize and scale faster."
          contentSx={{
            minHeight: {
              md: '170px',
              bg: '160px',
              gr: '120px',
            },
          }}
          listItems={[
            'Tiered commissions that align with your growth',
            'Strategy, campaign execution, and analytics',
            'Managed directly within the E2E Tool for seamless tracking',
          ]}
          listItemsSx={{
            minHeight: {
              md: '162px',
              bg: '108px',
              gr: '0',
            },
          }}
          buttonText="Explore Spona Performance"
          buttonDestination="/spona-media"
        />
      </Stack>
    </Stack>
  </Stack>
);

export default FourSolutions;
