import { Box, Stack, Typography } from '@mui/material';
import { primaryLight, secondaryOrange } from '../../../../utils/color';
import { LAYOUT_DEFAULTS } from '../../../../utils/constants';
import { ReactComponent as PersonCheck } from '../../../../assets/icons/person-check-fill.svg';
import { ReactComponent as SyncAltIcon } from '../../../../assets/icons/sync-alt-icon.svg';
import { ReactComponent as StackIcon } from '../../../../assets/icons/stack.svg';
import { ReactComponent as HandThumbsUpIcon } from '../../../../assets/icons/hand-thumbs-up.svg';
import HowItWorkItem from './components/HowItWorkItem';
import SponaCircleLeft from '../../../../assets/img/public-pages/spona-circle-left_color.png';

const HowItWork = () => (
  <Stack
    id="how-it-works"
    width="100%"
    bgcolor={primaryLight[100]}
    sx={{
      position: 'relative',
      py: { xs: '25%', sm: '8.75rem' },
      pb: '13.75rem !important',
    }}
  >
    <Box
      component="img"
      src={SponaCircleLeft}
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '30%',
        // height: '30%',
        zIndex: 999,
        // transform: 'translate(-50%, -50%)',
        maxWidth: '600px',
      }}
    />
    <Stack spacing={8} sx={{ ...LAYOUT_DEFAULTS }}>
      <Stack spacing={3} alignItems="center">
        <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
          How it Works
        </Typography>
        <Typography sx={{ typography: { xs: 'publicBody3Regular', lg: 'publicBody2Regular' } }}>
          We’ve made it simple for you.
        </Typography>
      </Stack>

      <Stack spacing={12.5}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={12.5}
          justifyContent="center"
          alignItems="center"
        >
          <HowItWorkItem
            Icon={PersonCheck}
            title="1. Create Your Account"
            content="Sign up to access our marketplace, tools, and marketing experts"
          />
          <HowItWorkItem
            Icon={SyncAltIcon}
            title="2. Choose Your Path"
            content="Hire talent, offer your services, manage new or existing projects, or tap into Spona Media for specialized campaigns."
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={12.5}
          justifyContent="center"
          alignItems="center"
        >
          <HowItWorkItem
            Icon={StackIcon}
            title="3. Manage & Collaborate"
            content="Track tasks, milestones, and performance using our E2E Tool—no matter which service you’re using."
          />
          <HowItWorkItem
            Icon={HandThumbsUpIcon}
            title="4. Grow & Optimize"
            content="Scale up your team, refine your processes, and boost your marketing with analytics-driven insights."
          />
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);
export default HowItWork;
