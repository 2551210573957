import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { mapMembershipRole } from 'tdc-web-backend/enums/enums';
import TableWithChipFilters from '../../../../../../../components/table/TableWithChipFilters';
import StatusChipField from '../../../../../../../components/StatusChipField/StatusChipField';
import CustomButton from '../../../../../../../components/button/CustomButton';
import CustomTooltip from '../../../../../../../components/tooltip/CustomTooltip';
import { AddModalData } from '../AddModal';

const sxButton = {
  minWidth: '90px',
  fontSize: '12px',
  minHeight: '30px',
  height: '35px',
};

type InvitationsProps = {
  data: any;
  onResendSelected: (data: AddModalData) => void;
  onRevokeSelected: (id: string) => void;
};

const Invitations = ({ data, onResendSelected, onRevokeSelected }: InvitationsProps) => {
  const { resourceLimit, chipFilters, activeChip, ...props } = data;

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTooltip title={params.value} placement="top">
          <Typography
            className="textOverflowEllipsis"
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
            }}
          >
            {params.value}
          </Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'fullName',
      headerName: 'Name',
      editable: false,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTooltip
          title={`${params.row.firstName || ''} ${params.row.lastName || ''}`}
          placement="top"
        >
          <Typography
            className="textOverflowEllipsis"
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
            }}
          >
            {`${params.row.firstName || ''} ${params.row.lastName || ''}`}
          </Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'jobTitle',
      headerName: 'Job title',
      editable: false,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTooltip title={params.value} placement="top">
          <Typography
            className="textOverflowEllipsis"
            sx={{
              fontSize: '0.875rem',
            }}
          >
            {params.value}
          </Typography>
        </CustomTooltip>
      ),
    },

    {
      field: 'role',
      headerName: 'Role',
      editable: false,
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTooltip
          title={(params.value ? mapMembershipRole.get(params.value) : '') as string}
          placement="top"
        >
          <Typography className="textOverflowEllipsis" sx={{ fontSize: '0.875rem' }}>
            {params.value ? mapMembershipRole.get(params.value) : ''}
          </Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => <StatusChipField text={params.value} />,
    },
    {
      field: 'edit',
      headerName: 'CTA',
      editable: false,
      flex: 0.1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      hide: activeChip !== 0,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <CustomButton
            variant="primary"
            onClick={() => {
              onResendSelected({
                email: params.row.email,
                firstName: params.row.firstName,
                lastName: params.row.lastName,
                jobTitle: params.row.jobTitle,
                role: params.row.role,
              });
            }}
            sx={{ mr: '5px', ...sxButton }}
          >
            Resend
          </CustomButton>
          <CustomButton
            variant="secondary"
            color="error"
            onClick={() => {
              onRevokeSelected(params.row.id);
            }}
            sx={{ color: 'secondaryPink.700', borderColor: 'secondaryPink.200', ...sxButton }}
          >
            Revoke
          </CustomButton>
        </>
      ),
    },
  ];

  return (
    <TableWithChipFilters
      numberOfRowsPerPage={resourceLimit}
      columns={columns}
      withElements={{
        withTitle: 'Invitations',
      }}
      chipFilters={
        chipFilters as { label: string; isLoading: boolean; count: number | undefined }[]
      }
      sx={{
        '& .MuiDataGrid-cell': {
          overflowX: 'auto',
        },
        height: '300px',
      }}
      activeChip={activeChip}
      {...props}
    />
  );
};

export default Invitations;
