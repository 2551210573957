type NavPageLinkType = {
  type: 'link' | 'subtitle';
  id: string;
  title: string;
  to: string;
};

export type NavPage =
  | {
      type: 'dropdown';
      id: string;
      title: string;
      links: NavPageLinkType[];
    }
  | {
      type: 'link' | 'scroll';
      id: string;
      title: string;
      to: string;
    };

export const pages: NavPage[] = [
  {
    type: 'dropdown',
    id: '100',
    title: 'Solutions',
    links: [
      { type: 'link', id: '101', title: 'Hire', to: '/how-to-hire' },
      { type: 'link', id: '102', title: 'Offer Services', to: '/how-to-find-work' },
      { type: 'link', id: '103', title: 'Managment Tool', to: '/how-to-manage-buyer' },
      { type: 'link', id: '104', title: 'Spona Performance', to: '/spona-media' },
    ],
  },
  {
    type: 'scroll',
    id: '200',
    title: 'How it works',
    to: '/#how-it-works',
  },
  {
    type: 'dropdown',
    id: '500',
    title: 'Resources',
    links: [
      { type: 'link', id: '501', title: 'Client Success Stories ', to: '/case-study-archive' },
      { type: 'link', id: '502', title: 'Blogs / News & Updates', to: '/blog-archive' },
    ],
  },
  {
    type: 'dropdown',
    id: '600',
    title: 'About Spona',
    links: [
      { type: 'link', id: '601', title: 'Our Mission', to: '/about-us' },
      { type: 'link', id: '602', title: 'Work With Us', to: '/work-with-us' },
      { type: 'link', id: '603', title: 'Contact', to: '/contact-us' },
    ],
  },
];
