import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import StatusChipField from '../../../../../components/StatusChipField/StatusChipField';
import CustomTable from '../../../../../components/custom-table/CustomTable';
import CustomRenderCell from '../../../../../components/custom-table/CustomRenderCell';
import { Typography } from '@mui/material';
import { useSellerHomescreenContext } from '../SellerHomescreenContext';
import { formatDateFns } from '../../../../../utils/helpers';

const ProjectPitchesTable = () => {
  const navigate = useNavigate();
  const { companyData } = useSellerHomescreenContext();

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => <StatusChipField text={params.value} />,
    },
    {
      field: 'projectName',
      headerName: 'Project Name',
      flex: 0.5,
      renderCell: CustomRenderCell,
    },
    {
      // date when it got pitched
      field: 'published',
      flex: 0.35,
      headerName: 'Pitched',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        if (params.value !== null) {
          return <Typography>{formatDateFns(params.value)}</Typography>;
        }

        return '-';
      },
    },
    // FIXME: finish this up once backend returns chat id within the timed-project-pitches response
    // {
    //   field: 'chat',
    //   headerName: '',
    //   disableColumnMenu: true,
    //   sortable: false,
    //   flex: 0.05,
    //   align: 'right',
    //   renderCell: (params: GridRenderCellParams) => {
    //     const shouldEnableChatIcon =
    //       params.row.status === EnumTimedProjectPitchStatus.SecondRound ||
    //       params.row.status === EnumTimedProjectPitchStatus.Hired ||
    //       params.row.status === EnumTimedProjectPitchStatus.SecondRoundRejected;

    //     return (
    //       <IconButton
    //         disabled={!shouldEnableChatIcon}
    //         onClick={(e) => {
    //           navigate(`./chat/}`);
    //           e.stopPropagation();
    //         }}
    //       >
    //         <ChatIcon fill={shouldEnableChatIcon ? primaryDark[500] : primaryDark[400]} />
    //       </IconButton>
    //     );
    //   },
    // },
  ];

  return (
    <>
      {companyData && (
        <CustomTable
          disableTabView
          disableSelectionOnClick
          resource={`timed-project-pitches?company=${companyData.id}`}
          columns={columns}
          height="215px"
          withDashboardCard={{
            headline: 'Project Pitches',
            linkText: 'View all',
            link: './pitches',
            sx: { px: 2, width: { md: '100%' }, '& .MuiDataGrid-cell': { px: '0px !important' } },
          }}
          onRowClick={(params) => {
            navigate('/dashboard/pitches', { state: params.row.content });
          }}
          visualStyle="secondary"
          sx={{ '.MuiDataGrid-row': { '.MuiDataGrid-cell:first-child': { px: 0.5 } } }}
        />
      )}
    </>
  );
};

export default ProjectPitchesTable;
