/* eslint-disable no-restricted-syntax */
/* eslint-disable arrow-body-style */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Typography, Stack, Divider, SxProps, Theme, Checkbox, MenuItem } from '@mui/material';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import { MilestoneDto, UpdateMilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { useParams } from 'react-router-dom';
import { SubtaskDto } from 'tdc-web-backend/subtasks/schemas';
import { GridColDef, GridRowId, GridRenderCellParams } from '@mui/x-data-grid';
import { ReactComponent as FilesEarmarkIcon } from '../../assets/icons/layout-icons/FilesEarmarkIcon.svg';
import { ReactComponent as RadioButton } from '../../assets/icons/layout-icons/RadioButton.svg';
import ControlledSelectInput from '../custom-inputs/ControlledSelectInput';
import useGetMany from '../../utils/hooks/crud-hooks/useGetMany';
import useUpdate from '../../utils/hooks/crud-hooks/useUpdate';
import CustomModal from '../modal/CustomModal';
import { secondaryBlue, secondaryGreen } from '../../utils/color';
import CustomTable from '../custom-table/CustomTable';
import CustomButton from '../button/CustomButton';
import { useRefresh } from '../../utils/hooks/crud-hooks/useRefresh';
import { EnumProjectTaskStatus } from 'tdc-web-backend/enums/enums';
import { formatDateFns } from '../../utils/helpers';
import useAuth from '../../utils/hooks/useAuth';

export interface CopyTasksModalProps<TData, TError> {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  previousMilestone?: MilestoneDto;
  recurringMilestones?: MilestoneDto[];
  sx?: SxProps<Theme> | undefined;
  width?: string;
}

const MilestoneNameAndStartEndRow = ({ data }: { data: MilestoneDto }) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
    <Typography variant="body3" sx={{ lineHeight: 'unset' }}>
      {data.name}
    </Typography>

    <Typography variant="body3" sx={{ lineHeight: 'unset' }}>
      {`${formatDateFns(data.start)} - ${formatDateFns(data.end)}`}
    </Typography>
  </Stack>
);

const CopyTasksModal = <TData, TError>({
  isModalOpen,
  setIsModalOpen,
  previousMilestone,
  recurringMilestones,
  sx,
  width,
}: CopyTasksModalProps<TData, TError>) => {
  const refresh = useRefresh();
  const params = useParams();
  const {
    authData: { userData: currentUser },
  } = useAuth();

  const UncheckedIcon = () => {
    return (
      <Box
        sx={{
          border: '3px solid',
          borderColor: 'primaryDark.300',
          borderRadius: '50%',
          width: 18,
          height: 18,
        }}
      />
    );
  };

  const CheckedIcon = () => {
    return <RadioButton fill={secondaryGreen[500]} style={{ width: 30, height: 30 }} />;
  };

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      recurringMilestoneId: previousMilestone?.id || '',
    },
  });

  const { control, reset, handleSubmit, getValues } = formMethods;

  const { data: tasks } = useGetMany<TaskDto>({
    enabled: !!useWatch({ control, name: 'recurringMilestoneId' }),
    resource: `/tasks/?milestone=${useWatch({ control, name: 'recurringMilestoneId' })}&company=${
      currentUser?.membership?.company
    }`,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: copyTasks } = useUpdate<MilestoneDto, UpdateMilestoneDto>({
    resource: '/milestones',
  });

  const taskData = tasks?.data?.results;

  // contains only ids of tasks
  const [selectedTasksToCopy, setSelectedTasksToCopy] = useState<GridRowId[]>([]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Task',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Typography fontWeight={500} color="primaryDark.600">
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'subtasksCount',
      headerName: 'Subtasks',
      flex: 0.2,

      align: 'center',
      renderCell: (params: GridRenderCellParams) => (
        <Typography fontWeight={500} color="primaryDark.600">
          {params.value}
        </Typography>
      ),
    },
  ];

  const onSubmit = () => {
    const tempTasks: UpdateMilestoneDto['tasks'] = [];
    // filter out from taskData all ids which have been selected (out of selectedTasksToCopy array)
    const checkedTasksToCopy = taskData?.filter((task) => selectedTasksToCopy.includes(task.id));

    if (checkedTasksToCopy) {
      for (const task of checkedTasksToCopy) {
        tempTasks.push({
          name: task.name,
          description: task.description,
          status: EnumProjectTaskStatus.Pending,
          start: task.start,
          end: task.end,
          subtasks:
            task.subtasks && task.subtasks.length > 0
              ? task.subtasks.map((subtask: SubtaskDto) => ({
                  name: subtask.name,
                  status: false,
                  end: subtask.end,
                }))
              : [],
        });
      }
    }

    if (tempTasks.length > 0) {
      copyTasks(
        {
          id: params.milestoneId as string,
          data: {
            tasks: tempTasks,
          },
        },
        {
          onSuccess: () => {
            refresh();
            setIsModalOpen(false);
          },
        },
      );
    }
  };

  // populate the rhf default state on when previousMilestone comes
  useEffect(() => {
    if (previousMilestone) {
      reset({ recurringMilestoneId: previousMilestone.id });
    }
  }, [previousMilestone]);

  return (
    <CustomModal open={isModalOpen} onClose={() => setIsModalOpen(false)} width={width}>
      <Stack spacing={3} height="100%" justifyContent="space-between">
        <Stack spacing={2} direction="row" alignItems="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              bgcolor: '#E5E8FF',
              width: 50,
              height: 50,
              borderRadius: '6px',
              px: 2,
              py: 1,
            }}
          >
            <FilesEarmarkIcon fill={secondaryBlue[700]} style={{ width: 24, height: 24 }} />
          </Stack>

          <Typography variant="body2" color="primaryDark.600" fontWeight={600}>
            Copy tasks to new milestone
          </Typography>
        </Stack>

        <Divider sx={{ borderColor: '#E5E8FF' }} />

        <Stack spacing={4}>
          <Typography color="primaryDark.500">
            Choose the milestone and tasks you'd like to copy to the next recurring milestone.
          </Typography>

          <FormProvider {...formMethods}>
            <Box
              id="copy-tasks-form"
              component="form"
              sx={{ width: '100%' }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <ControlledSelectInput<MilestoneDto>
                name="recurringMilestoneId"
                defaultValue={useWatch({ control, name: 'recurringMilestoneId' })}
                label="Choose milestone"
                choices={recurringMilestones as MilestoneDto[]}
                arrayPropName="name"
                renderCustomSelectedValue={(data) => <MilestoneNameAndStartEndRow data={data} />}
                renderCustomMenuItem={(data) => (
                  <MenuItem value={data.id}>
                    <MilestoneNameAndStartEndRow data={data} />
                  </MenuItem>
                )}
                sx={{ mb: '0 !important' }}
              />
            </Box>
          </FormProvider>
        </Stack>

        <Box sx={{ mt: '0 !important' }}>
          <CustomTable
            resource="tasks"
            columns={columns}
            searchParams={{
              milestone: useWatch({ control, name: 'recurringMilestoneId' }),
              company: currentUser?.membership?.company,
            }}
            checkboxSelection
            components={{
              BaseCheckbox: (props) => {
                return (
                  <Checkbox
                    indeterminateIcon={props.checked ? <CheckedIcon /> : <UncheckedIcon />}
                    icon={<UncheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                    sx={{ p: 0 }}
                    {...props}
                  />
                );
              },
            }}
            onSelectionModelChange={(taskIndex) => setSelectedTasksToCopy(taskIndex)}
            disableTabView
            sx={{
              height: '340px',
              '.Mui-selected': {
                border: 'none',
                backgroundColor: 'white !important',
              },
              '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                display: 'none',
              },
            }}
            hideFooter
          />
        </Box>

        {/* buttons */}
        <Stack spacing={2}>
          <Divider />

          <Stack spacing={2} direction="row" justifyContent="end">
            <CustomButton
              form="copy-tasks-form"
              type="submit"
              onClick={() => setIsModalOpen(false)}
              variant="secondary"
            >
              Cancel
            </CustomButton>

            <CustomButton form="copy-tasks-form" type="submit" variant="primary">
              Confirm
            </CustomButton>
          </Stack>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default CopyTasksModal;
