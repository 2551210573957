import { Stack, Typography } from '@mui/material';
import { LAYOUT_DEFAULTS } from '../../../../utils/constants';
import { EnumPublicPagesImage } from '../../../../utils/enums';
import { getPublicImageUrl } from '../../../../utils/helpers';
import WhyChoseSponaItem, { IWhyChoseSponaItemProps } from './components/WhyChoseSponaItem';

const items = [
  {
    id: 'all-in-one-ecosystem',
    title: 'All-in-One Ecosystem',
    content:
      'No more juggling multiple vendors. Hire pros, manage end-to-end, and boost marketing from a single dashboard.',
    image: getPublicImageUrl(EnumPublicPagesImage.HomeHandshake),
    color: 'secondaryBlue.50',
    alt: 'Home handshake illustration',
    reverse: false,
  },
  {
    id: 'tool-talent-marketing',
    title: 'Tool + Talent + Marketing',
    content:
      'Gain the flexibility to hire experts, offer your own expertise, use the E2E Tool, and execute marketing campaigns without skipping a beat.',
    image: getPublicImageUrl(EnumPublicPagesImage.KpiPaymnents),
    color: 'secondaryBlue.50',
    alt: 'Illustration of the dashboard on Spona',
    reverse: true,
  },
  {
    id: 'kpi-driven-transparency',
    title: 'KPI-Driven Transparency',
    content:
      'Track performance-based milestones and budgets across every project—so you always know you’re getting a return on your investment.',
    image: getPublicImageUrl(EnumPublicPagesImage.HomeHealth),
    color: 'secondaryBlue.50',
    alt: 'Illustration of the dashboard on Spona',
    reverse: false,
  },
  {
    id: 'global-network',
    title: 'Global Network',
    content:
      'Whether you’re looking for specialized talent or top-tier marketing consultants, our worldwide community has you covered.',
    image: getPublicImageUrl(EnumPublicPagesImage.HomeMapSvg),
    color: 'secondaryBlue.50',
    alt: 'Illustration of global network',
    reverse: true,
  },
];

const WhyChoseSpona = () => (
  <Stack
    spacing={10}
    sx={{
      // mt: { xs: -7, lg: '110px' },
      mt: { xs: '4rem', lg: '110px' },
      ...LAYOUT_DEFAULTS,
      mb: { xs: '50px', lg: '100px' },
    }}
    justifyContent="center"
    alignItems="center"
  >
    <Stack spacing={2} sx={{ alignSelf: { xs: 'center', lg: 'start' } }}>
      <Typography
        sx={{
          typography: { xs: 'publicHeading4', lg: 'publicHeading3' },
          alignSelf: { xs: 'center', lg: 'start' },
        }}
        textAlign="center"
      >
        Why Choose Spona?
      </Typography>
      <Typography
        sx={{
          typography: { xs: 'publicBody3Regular', lg: 'publicBody2Regular' },
          alignSelf: { xs: 'center', lg: 'start' },
        }}
        textAlign="center"
      >
        We help you manage everything in one place.
      </Typography>
    </Stack>

    <Stack spacing={4} width="100%">
      {items.map(
        ({
          title,
          content,
          image,
          color,
          alt,
          id,
          reverse,
        }: IWhyChoseSponaItemProps & { id: string }) => (
          <WhyChoseSponaItem
            key={id}
            title={title}
            content={content}
            image={image}
            color={color}
            alt={alt}
            reverse={reverse}
          />
        ),
      )}
    </Stack>
  </Stack>
);
export default WhyChoseSpona;
