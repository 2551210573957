import { Typography, Stack, Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { CompanyRole, EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import { CompanySubscriptionInfo } from 'tdc-web-backend/companies/schemas';
import { calculateProjectProgress, formatDateFns } from '../../../utils/helpers';
import ProgressBar from '../../../components/progress-bar/ProgressBar';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import CustomTable from '../../../components/custom-table/CustomTable';
import CustomButton, { Variants } from '../../../components/button/CustomButton';
import { ReactComponent as PlusIcon } from '../../../assets/icons/button-icons/PlusIcon.svg';
import useAuth from '../../../utils/hooks/useAuth';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import useUserCompany from '../../../utils/hooks/useUserCompany';
import CustomTooltip from '../../../components/tooltip/CustomTooltip';

export interface ProjectListTableProps {
  dataGridHeight?: string | number;
}

const ProjectListTable = ({ dataGridHeight = '300px' }: ProjectListTableProps) => {
  const navigate = useNavigate();

  const {
    authData: { userData },
  } = useAuth();
  const userCompany = useUserCompany();
  const isBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);

  const { data: subscriptionInfoResponse, isLoading: isGetDataLoading } =
    useGetOne<CompanySubscriptionInfo>({
      resource: `companies/${userData?.membership?.company}/active-subscription`,
      enabled: !!userData?.membership?.company,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    });
  const subscriptionInfo = subscriptionInfoResponse?.data;

  const getRequestNewProjectProps = () => {
    const pricingUrl = isBuyer ? '/how-to-manage-buyer/pricing' : '/how-to-manage-seller/pricing';
    if (!subscriptionInfo) {
      return {
        url: pricingUrl,
        text: 'Subscribe to request new project',
        variant: 'primary',
        icon: null,
      };
    }

    const { activeSubscription, noActiveProjects } = subscriptionInfo;
    if (!activeSubscription) {
      return {
        url: pricingUrl,
        text: 'Subscribe to request new project',
        variant: 'primary',
        icon: null,
      };
    }

    const { maxProjects } = activeSubscription.price.product;
    const hasUnlimitedProjects = maxProjects === -1;
    const hasAvailableProjects = maxProjects > noActiveProjects;

    if (hasUnlimitedProjects || hasAvailableProjects) {
      return {
        url: './project-request',
        text: 'request new project',
        variant: 'primaryLight',
        icon: <PlusIcon width="20px" height="20px" />,
      };
    }

    return {
      url: pricingUrl,
      text: 'Upgrade to request new project',
      variant: 'primaryLight',
      icon: null,
    };
  };

  const requestNewProjectProps = getRequestNewProjectProps();

  const columns: GridColDef[] = [
    {
      field: 'locked',
      headerName: '',
      flex: 0.002,
      renderCell: (params: GridRenderCellParams) => (
        <Stack spacing={0.4} direction="row" alignItems="center">
          {/* <StatusChipField text={params.value} /> */}
          {params.value && (
            <CustomTooltip
              title="You have exceeded the maximum number of open projects allowed under your current subscription plan."
              placement="top"
            >
              <LockOutlinedIcon sx={{ fontSize: '1rem', color: 'secondaryOrange.601' }} />
            </CustomTooltip>
          )}
        </Stack>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.19,
      renderCell: (params: GridRenderCellParams) => <StatusChipField text={params.value} />,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.29,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTooltip title={params.value} placement="top">
          <Typography
            variant="body2"
            fontWeight={600}
            sx={{
              color: 'primaryDark.600',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            {params.value}
          </Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'start',
      headerName: 'Start date',
      flex: 0.15,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value !== null) {
          return <Typography>{formatDateFns(params.value)}</Typography>;
        }

        return <Typography>-</Typography>;
      },
    },
    {
      field: 'end',
      headerName: 'End date',
      flex: 0.15,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value !== null) {
          return <Typography>{formatDateFns(params.value)}</Typography>;
        }

        return <Typography>-</Typography>;
      },
    },
    {
      field: 'milestoneCompletedCount',
      headerName: 'Progress',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => {
        const {
          row: {
            tasksCount,
            milestoneCount,
            tasksCompletedCount,
            tasksCanceledCount,
            milestoneCompletedCount,
            milestoneApprovedCount,
          },
        } = params;
        const percentageOfCompletion = calculateProjectProgress(
          tasksCount,
          milestoneCount,
          tasksCompletedCount,
          tasksCanceledCount,
          milestoneCompletedCount,
          milestoneApprovedCount,
        );

        return (
          <ProgressBar
            value={Number.isFinite(percentageOfCompletion) ? percentageOfCompletion : 0}
          />
        );
      },
    },
  ];

  const panelConfig = [
    {
      id: 'project_list-all',
      title: 'All',
    },
    {
      id: 'project_list-in_progress',
      title: 'In progress',
      search: { status: EnumProjectMilestoneStatus.InProgress },
    },
    {
      id: 'project_list-completed',
      title: 'Completed',
      search: { status: EnumProjectMilestoneStatus.Completed },
    },
    {
      id: 'project_list-pending',
      title: 'Pending',
      search: { status: EnumProjectMilestoneStatus.Pending },
    },
  ];

  return (
    <Stack direction="column" sx={{ height: 'fit-content', width: '100%' }} spacing={2}>
      <Box sx={{ position: 'relative' }}>
        {subscriptionInfo && (
          <CustomButton
            variant={requestNewProjectProps.variant as Variants}
            sx={{
              height: { md: '3.75rem', lg: '2.75rem' },
              width: { md: '8.5rem', lg: '10.5rem', gr: '13.5rem' },
              position: 'absolute',
              right: 0,
              top: { md: 0, lg: 10 },
              cursor: 'pointer',
              zIndex: 1,
            }}
            startIcon={requestNewProjectProps.icon}
            onClick={() => navigate(requestNewProjectProps.url)}
          >
            <Typography variant="bodyMedium3">{requestNewProjectProps.text}</Typography>
          </CustomButton>
        )}
        <CustomTable
          resource="projects"
          columns={columns}
          tabsClassName="react-joyride-step-9"
          className="react-joyride-step-10"
          panelConfig={panelConfig}
          searchParams={{ company: userData?.membership?.company }}
          onRowClick={(params) => navigate(`./project/${params.id}`)}
          height={dataGridHeight as string}
          hideFooter
        />
      </Box>
    </Stack>
  );
};

export default ProjectListTable;
