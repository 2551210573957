import { Box, Stack, Typography } from '@mui/material';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import { EnumPublicPagesImage } from '../../../utils/enums';
import { getPublicImageUrl } from '../../../utils/helpers';

const WhatIsSpona = () => (
  <Stack
    direction="row"
    alignItems="center"
    sx={{
      position: 'relative',
      bgcolor: 'primaryLight.100',
      px: { xs: 4, lg: 5 },
      py: { xs: 9, lg: 5, xl: 5 },
      pb: { xs: 3, sm: 6, lg: 5 },
      borderRadius: '20px',
      width: { xs: 'fit-content', xl: 'auto' },
      overflow: { xs: 'hidden', lg: 'visible' },
      mt: { xs: '30px', sm: '30px', md: '30px', lg: '30px' },
      my: 8,
      ...LAYOUT_DEFAULTS,
    }}
  >
    {/* Evolve Your Business text & description text & button */}
    <Stack spacing="36px" sx={{ maxWidth: { xs: '100%', lg: '55%' } }}>
      <Typography
        color="common.black"
        whiteSpace="pre-line"
        sx={{
          typography: { xs: 'publicHeading4', lg: 'publicHeading3' },
        }}
      >
        What is Spona
      </Typography>

      <Typography
        color="common.black"
        variant="publicBody4Light"
        sx={{
          maxWidth: { xs: '100%', md: '50%', lg: '90%' },
          zIndex: 10,
        }}
      >
        Spona is your
        <Typography variant="publicBody4" component="span">
          {' '}
          all-in-one solution for business growth.{' '}
        </Typography>
        Whether you need to hire expert talent, offer your professional services, manage complex
        projects, or launch performance marketing campaigns, our E2E Tool and integrated ecosystem
        keep everything organized and scalable.
      </Typography>

      <Box
        alt="Mock-up of a laptop showing a screenshot of Spona’s tool "
        component="img"
        src={getPublicImageUrl(EnumPublicPagesImage.Laptop2)}
        sx={{
          display: { xs: 'block', md: 'none' },
          // position: { xs: 'absolute' },
          bottom: 6,
          right: 10,
          width: { xs: '90%', lg: 'auto' },
        }}
      />
    </Stack>

    <Box
      alt="Mock-up of a laptop showing a screenshot of Spona’s tool "
      component="img"
      src={getPublicImageUrl(EnumPublicPagesImage.Laptop2)}
      sx={{
        display: { xs: 'none', md: 'block' },
        // position: { sm: 'absolute', xl: 'relative' },
        // right: { xs: 10, bg: 0, lg: -85, xxl: -150 },
        width: '54%',
      }}
    />
  </Stack>
);

export default WhatIsSpona;
