import { Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { mapMembershipRole } from 'tdc-web-backend/enums/enums';
import AddIcon from '@mui/icons-material/Add';
import TableWithChipFilters from '../../../../../../components/table/TableWithChipFilters';
import CustomButton from '../../../../../../components/button/CustomButton';
import useAuth from '../../../../../../utils/hooks/useAuth';
import CustomTooltip from '../../../../../../components/tooltip/CustomTooltip';
import useTable from '../../../../../../components/table/useTable';

const Team = ({ onAddNewClick }: { onAddNewClick?: () => void }) => {
  const {
    authData: { userData },
  } = useAuth();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTooltip title={params.value} placement="top">
          <Typography
            className="textOverflowEllipsis"
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
            }}
          >
            {params.value}
          </Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'fullName',
      headerName: 'Name',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTooltip
          title={`${params.row.firstName || ''} ${params.row.lastName || ''}`}
          placement="top"
        >
          <Typography
            className="textOverflowEllipsis"
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
            }}
          >
            {`${params.row.firstName || ''} ${params.row.lastName || ''}`}
          </Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'jobTitle',
      headerName: 'Job title',
      editable: false,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTooltip title={params.value} placement="top">
          <Typography
            className="textOverflowEllipsis"
            sx={{
              fontSize: '0.875rem',
            }}
          >
            {params.value}
          </Typography>
        </CustomTooltip>
      ),
    },

    {
      field: 'membership',
      headerName: 'Role',
      editable: false,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTooltip
          title={(params.value?.role ? mapMembershipRole.get(params.value.role) : '') as string}
          placement="top"
        >
          <Typography className="textOverflowEllipsis" sx={{ fontSize: '0.875rem' }}>
            {params.value?.role ? mapMembershipRole.get(params.value.role) : ''}
          </Typography>
        </CustomTooltip>
      ),
    },
  ];
  const { resourceLimit, chipFilters, ...props } = useTable({
    resourceUrl: `companies/members`,
    queryParams: `companyId=${userData?.membership?.company}`,
    withTabs: false,
  });

  return (
    <>
      <TableWithChipFilters
        numberOfRowsPerPage={resourceLimit}
        columns={columns}
        onRowClick={(e) => {
          if (e.row.id === userData?.id) {
            navigate(`../account`);
          } else {
            navigate(`../members/${e.row.id}`);
          }
        }}
        withElements={{
          withTitle: 'Members',
          withCta: (
            <CustomButton
              startIcon={<AddIcon sx={{ fontSize: '25px !important' }} />}
              onClick={onAddNewClick}
            >
              Add new
            </CustomButton>
          ),
        }}
        chipFilters={[]}
        sx={{
          '& .MuiDataGrid-cell': {
            overflowX: 'auto',
          },
          height: '470px',
        }}
        {...props}
      />
    </>
  );
};

export default Team;
