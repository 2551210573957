import { Box, Stack, Typography } from '@mui/material';
import { primaryLight } from '../../../../../utils/color';

export interface IWhyChoseSponaItemProps {
  // id: string;
  title: string;
  content: string;
  image: string;
  reverse?: boolean;
  color?: string;
  alt?: string;
}

const WhyChoseSponaItem = ({
  title,
  image,
  reverse = false,
  content,
  color = primaryLight[100],
  alt,
}: IWhyChoseSponaItemProps) => (
  <Box
    sx={{
      bgcolor: color,
      borderRadius: '20px',
      px: { xs: 2.5, lg: 9 },
      pt: { xs: 4.5, lg: 1 },
      py: { xs: 4.5, lg: 4 },
    }}
  >
    <Stack
      direction={{ xs: 'column', md: reverse ? 'row-reverse' : 'row' }}
      justifyContent="center"
      alignItems="center"
      spacing={{ xs: 2, lg: 8 }}
    >
      <Stack spacing={{ xs: 2, md: 'none' }}>
        <Typography variant="publicHeading4" pb="1rem">
          {title}
        </Typography>
        <Box
          display={{ xs: 'block', md: 'none' }}
          alt={alt}
          key={image}
          component="img"
          src={image}
          sx={{
            maxWidth: { xs: '100%', md: 'auto' },
            borderRadius: '20px 20px 0px 0px',
            height: '200px',
            //   pt: '2rem',
          }}
        />
        <Typography variant="publicBody4Regular" maxWidth="90%">
          {content}
        </Typography>
      </Stack>

      <Box
        display={{ xs: 'none', md: 'block' }}
        alt={alt}
        key={image}
        component="img"
        src={image}
        sx={{
          maxWidth: { xs: '100%', md: 'auto' },
          borderRadius: '20px 20px 0px 0px',
          height: '200px',
          //   pt: '2rem',
        }}
      />
    </Stack>
  </Box>
);

export default WhyChoseSponaItem;
