import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import HomepPageList from './HompageList';
import { primaryLight } from '../../../../../utils/color';
import CustomButton from '../../../../../components/button/CustomButton';

interface ISolutionItemProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  content: string;
  contentSx?: SxProps<Theme>;
  listItems: string[];
  listItemsSx?: SxProps<Theme>;
  buttonText: string;
  buttonDestination: string;
}

const SolutionItem = ({
  Icon,
  title,
  content,
  contentSx,
  listItems,
  listItemsSx,
  buttonText,
  buttonDestination,
}: ISolutionItemProps) => {
  const navigator = useNavigate();

  return (
    <Stack spacing={2} width={{ xs: '100%', md: '40%' }} sx={{ zIndex: 3 }}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '76px',
          height: '76px',
          backgroundColor: 'primaryLight.300',
          borderRadius: '38px',
        }}
      >
        <Icon fill={primaryLight[700]} width="38px" />
      </Stack>
      <Typography
        component="span"
        color="common.black"
        sx={{
          typography: { xs: 'publicBody5Regular', sm: 'publicBody3' },
          lineHeight: 'normal !important',
          fontSize: '1.125rem !important',
          minHeight: '2.5rem',
        }}
      >
        {title}
      </Typography>

      <Typography
        color="common.black"
        variant="publicBody3Regular"
        sx={{
          typography: { xs: 'publicBody5Regular', sm: 'publicBody3Regular' },
          lineHeight: 'normal !important',
          fontSize: '1.125rem !important',

          ...contentSx,
        }}
      >
        {content}
      </Typography>
      <HomepPageList items={listItems} sx={{ ...listItemsSx }} />

      <CustomButton
        variant="primary"
        sx={{
          width: 'fit-content',
          borderRadius: '6.25rem',
          height: '2rem',
          textTransform: 'none',
        }}
        endIcon={<ChevronRightIcon sx={{ fontSize: '1.75rem !important' }} />}
        onClick={() => navigator(buttonDestination)}
      >
        {buttonText}
      </CustomButton>
    </Stack>
  );
};
export default SolutionItem;
