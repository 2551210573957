import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/button/CustomButton';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import useAuth from '../../../utils/hooks/useAuth';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums';
import gameboost from '../../../assets/img/public-pages/gameboost.svg';
import lightFilm from '../../../assets/img/public-pages/lightfilm.svg';
import moi from '../../../assets/img/public-pages/moi.svg';
import blvck from '../../../assets/img/public-pages/blvck.svg';

const companyLogos = [
  { src: gameboost, alt: 'Game Boost' },
  { src: lightFilm, alt: 'Light Film' },
  { src: moi, alt: 'Museum of illusions' },
  { src: blvck, alt: 'BLVCK' },
];

const HomepageHero = () => {
  const {
    authData: { accessToken },
  } = useAuth();

  const hasUser = accessToken !== null;
  const theme = useTheme();
  const navigate = useNavigate();
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      spacing={3}
      justifyContent="center"
      alignItems="center"
      sx={{
        ...LAYOUT_DEFAULTS,
        marginTop: `${PUBLIC_APPBAR_HEIGHT + 40}px`,
        textAlign: 'center',
        mb: '100px',
      }}
    >
      <Stack justifyContent="center" alignItems="center" width="100%">
        <Typography
          display={{ xs: 'grid', md: 'grid' }}
          sx={{
            fontSize: { xs: '2.9rem', md: '4.5rem', lg: '5.5rem', gr: '7.5rem' },
            fontFamily: 'ABeeZee',
            fontStyle: 'normal',
            lineHeight: '106%',
          }}
        >
          Spona: One Platform{' '}
          <Typography
            component="span"
            sx={{
              position: 'relative',
              fontSize: { xs: '2.9rem', md: '4.5rem', lg: '5.5rem', gr: '7.5rem' },
              fontFamily: 'ABeeZee',
              fontStyle: 'normal',
              lineHeight: '106%',
            }}
          >
            Infinite{' '}
            <Typography
              component="span"
              sx={{
                position: 'relative',
                fontSize: { xs: '2.9rem', md: '4.5rem', lg: '5.5rem', gr: '7.5rem' },
                fontFamily: 'ABeeZee',
                fontStyle: 'normal',
                lineHeight: '106%',
              }}
            >
              Possibilities.
              <Box
                sx={{
                  backgroundColor: 'primaryLight.500',
                  width: '100%',
                  height: isLessThanMedium ? '0.5rem' : '1rem',
                  borderRadius: '7rem',
                  position: 'absolute',
                  bottom: { xs: '-15px', md: '-20px' },
                  left: 0,
                  zIndex: -1,
                }}
              />
            </Typography>
          </Typography>
        </Typography>
      </Stack>

      <Stack
        spacing={{ md: 8 }}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ pt: '3rem' }}
        width="100%"
      >
        {/* about us text & company icons */}
        <Stack
          spacing={6}
          maxWidth={{ xs: '100%', xl: '100%' }}
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack spacing={3} sx={{ width: { xs: '100%', md: '50%' } }}>
            <Typography
              align={isLessThanMedium ? 'center' : 'left'}
              sx={{
                zIndex: 9,
                fontSize: { xs: '0.875rem', md: '1rem', lg: '1.25rem', gr: '1.5rem' },
                fontFamily: 'ABeeZee',
                fontStyle: 'normal',
                lineHeight: '145%',
              }}
            >
              Hire trusted experts, offer your professional services, manage every aspect of your
              projects, and power your marketing through Spona Media — all from a single platform.
            </Typography>

            {!hasUser && (
              <CustomButton
                variant="primary"
                withNextArrow
                sx={{
                  width: { xs: '55%', md: '45%' },
                  py: { xs: '18px' },
                }}
                onClick={() => navigate('/register')}
              >
                Get started
              </CustomButton>
            )}
            <Grid
              container
              columnSpacing={{ xs: 0, md: 4, lg: 4 }}
              rowSpacing={2}
              sx={{ pt: 3, ml: { xs: '-1rem', md: 0 } }}
            >
              {companyLogos.map((logo) => (
                <Grid item xs={6} sm={3} alignItems="center" display="flex" justifyContent="center">
                  <Box key={logo.src} component="img" src={logo.src} alt={logo.alt} />
                </Grid>
              ))}
            </Grid>
          </Stack>

          <Stack width="50%" alignItems="flex-end" sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Box
              component="img"
              src={getPublicImageUrl(EnumPublicPagesImage.HomeOffice)}
              alt="Team discussion by the desk. Casual meeting in a modern office."
              sx={{ width: { md: '35vw', xl: '30vw' } }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HomepageHero;
