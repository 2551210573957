import { Stack, Typography } from '@mui/material';
import { primaryLight } from '../../../../../utils/color';

interface IHowItWorkItemProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  content: string;
}

const HowItWorkItem = ({ Icon, title, content }: IHowItWorkItemProps) => (
  <Stack spacing={3} alignItems="center" justifyContent="flwex-start" sx={{ maxWidth: '420px' }}>
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '76px',
        height: '76px',
        backgroundColor: 'primaryLight.300',
        borderRadius: '38px',
        maxWidth: '76px',
        maxHeight: '76px',
      }}
    >
      <Icon fill={primaryLight[700]} width="38px" />
    </Stack>
    <Typography
      variant="publicBody2"
      sx={{ textAlign: 'center', fontSize: { xs: '1.25rem', lg: '1.5rem' } }}
    >
      {title}
    </Typography>
    <Typography
      variant="publicBody2Regular"
      sx={{ textAlign: 'center', fontSize: { xs: '1.25rem', lg: '1.5rem' } }}
    >
      {content}
    </Typography>
  </Stack>
);
export default HowItWorkItem;
