import { Stack, SxProps, Theme, Typography, Box, IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { CompanyRole, EnumCurrency, EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import { useState } from 'react';
import { formatCurrency, formatDateFns } from '../../../../utils/helpers';
import CustomTable from '../../../../components/custom-table/CustomTable';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import LayeredCircularProgress from '../../../../components/layered-circular-progress/LayeredCircularProgress';
import InvoiceUploadModal from '../modals/InvoiceUploadModal';
import CustomNoRowsOverlay from '../../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';
import CustomTooltip from '../../../../components/tooltip/CustomTooltip';
import useUserCompany from '../../../../utils/hooks/useUserCompany';
import { ReactComponent as CirclePlusIcon } from '../../../../assets/icons/project-icons/CirclePlusIcon.svg';
import { ReactComponent as FileDownloadIcon } from '../../../../assets/icons/project-icons/FileDownloadIcon.svg';
import { ReactComponent as EmptyFileIcon } from '../../../../assets/icons/project-icons/EmptyFileIcon.svg';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';

export interface PaymentsAndMilestonesProps {
  currency: EnumCurrency;
  sx?: SxProps<Theme> | undefined;
  projectId: string;
  projectLocked?: boolean;
}

const PaymentsAndMilestones = ({
  projectId,
  sx,
  currency,
  projectLocked = false,
}: PaymentsAndMilestonesProps) => {
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const userType = useUserCompany()?.roles;

  const isBuyer = userType?.includes(CompanyRole.Buyer);

  const { data: milestonesResponse } = useGetMany<MilestoneDto>({
    resource: `/milestones?project=${projectId}`,
    enabled: !!projectId,
  });
  const rows = milestonesResponse?.data.results;

  const columns: GridColDef[] = [
    {
      field: 'paymentStatus',
      headerName: 'Status',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <StatusChipField text={params.value} componentReturnType="dot" />
      ),
    },
    {
      field: 'name',
      headerName: 'Milestone name',
      flex: 0.6,
      renderCell: (params: GridRenderCellParams) => (
        <CustomTooltip title={params.value} placement="top">
          <Typography
            variant="body2"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {params.value}
          </Typography>
        </CustomTooltip>
      ),
    },
    {
      field: 'paid',
      headerName: 'Paid',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value !== null) {
          return <Typography>{formatDateFns(params.value)}</Typography>;
        }

        return <Typography>-</Typography>;
      },
    },
    {
      field: 'budget',
      headerName: 'Amount',
      align: 'right',
      headerAlign: 'right',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>
          {formatCurrency(params.value, currency)}
        </Typography>
      ),
    },
    {
      field: 'invoice',
      headerName: ' ',
      align: 'right',
      headerAlign: 'right',
      flex: 0.01,
      renderCell: (params: GridRenderCellParams) => {
        const ifIsDownloadDisabled = isBuyer && !params.row.buyerInvoice;
        const ifIsUploadDisabled =
          params.row.status !== EnumProjectMilestoneStatus.Approved || projectLocked === true;

        return (
          <>
            {!isBuyer && !params.row.sellerInvoice ? (
              <IconButton
                sx={{
                  color: '#fff',
                  fontSize: '0.8rem',
                }}
                disabled={ifIsUploadDisabled}
                color="secondary"
                onClick={() => setIsInvoiceModalOpen(true)}
              >
                <CustomTooltip
                  placement="top"
                  title="Upload invoice"
                  className="react-joyride-step-2-active-project"
                >
                  <CirclePlusIcon fill={ifIsUploadDisabled ? '#A5AAB9' : '#6D51F6'} />
                </CustomTooltip>
              </IconButton>
            ) : (
              <a
                href={isBuyer ? params.row.buyerInvoice : params.row.sellerInvoice}
                target="_blank"
                rel="noreferrer"
              >
                <IconButton
                  sx={{
                    color: '#fff',
                    fontSize: '0.8rem',
                  }}
                  disabled={ifIsDownloadDisabled}
                  color="secondary"
                  className="react-joyride-step-3-active-project"
                >
                  <CustomTooltip placement="top" title="Download invoice">
                    {params.row.buyerInvoice ? (
                      <FileDownloadIcon
                        href={params.row.sellerInvoice}
                        fill={ifIsDownloadDisabled ? '#A5AAB9' : '#384572'}
                      />
                    ) : (
                      <EmptyFileIcon />
                    )}
                  </CustomTooltip>
                </IconButton>
              </a>
            )}

            {/* render only if isInvoiceModalOpen */}
            {isInvoiceModalOpen && (
              <InvoiceUploadModal
                isOpen={isInvoiceModalOpen}
                setIsOpen={setIsInvoiceModalOpen}
                data={params.row}
              />
            )}
          </>
        );
      },
    },
  ];

  const handleDebts = () => {
    const sort = rows?.reduce(
      (a, b) => {
        if (b.paid === null) {
          // eslint-disable-next-line no-param-reassign
          a.unpaid += b.budget;
        }
        return a;
      },
      {
        unpaid: 0,
      },
    );
    return sort?.unpaid;
  };

  const handlePaid = () => {
    const sort = rows?.reduce(
      (a, b) => {
        if (b.paid !== null) {
          // eslint-disable-next-line no-param-reassign
          a.paid += b.budget;
        }

        return a;
      },
      {
        paid: 0,
      },
    );
    return sort?.paid;
  };

  return (
    <Stack
      spacing={4}
      direction={{ sm: 'column', xl: 'row' }}
      width="100%"
      height="100%"
      justifyContent="space-between"
      position="relative"
    >
      {!rows?.length && <CustomNoRowsOverlay />}
      <Stack
        sx={{
          width: '35%',
          overflow: 'visible',
          verticalAlign: 'center',
        }}
        margin="auto"
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <LayeredCircularProgress
          colorOuter="secondaryGreen.500"
          colorInner="primaryDark.300"
          valueInner={handleDebts() ?? 0}
          valueOuter={handlePaid() ?? 0}
        />
        <Stack
          margin={{ md: 'initial', lg: 'auto !important' }}
          justifyContent="center"
          spacing={2}
        >
          <Stack direction="row" spacing={2}>
            <Box
              sx={{
                borderRadius: '50%',
                width: 18,
                height: 18,
                border: '3px solid',
                borderColor: 'secondaryGreen.200',
                backgroundColor: 'secondaryGreen.500',
                alignSelf: 'center',
              }}
            />
            <Stack>
              <Typography variant="heading4" color="primaryDark.600">
                Paid
              </Typography>
              <Typography variant="body3" color="primaryDark.500">
                {formatCurrency(handlePaid() ?? 0, EnumCurrency.Usd)}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} margin="auto">
            <Box
              sx={{
                borderRadius: '50%',
                width: 18,
                height: 18,
                border: '3px solid',
                borderColor: 'primaryDark.200',
                backgroundColor: 'primaryDark.400',
                alignSelf: 'center',
              }}
            />
            <Stack>
              <Typography variant="heading4" color="primaryDark.600">
                Due
              </Typography>
              <Typography variant="body3" color="primaryDark.500">
                {formatCurrency(handleDebts() ?? 0, EnumCurrency.Usd)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack width="100%">
        <CustomTable
          columns={columns}
          resource="milestones"
          disableTabView
          searchParams={{ project: projectId, limit: 999 }}
          hideFooter
          disableNoRecords
          sx={{
            '.MuiDataGrid-row': {
              borderBottom: '1px solid',
              borderColor: 'secondaryBlue.100',
            },
          }}
          autoHeight
        />
      </Stack>
    </Stack>
  );
};

export default PaymentsAndMilestones;
