import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FormProvider, useForm } from 'react-hook-form';
import useCreate from '../../../utils/hooks/crud-hooks/useCreate';
import BaseInputLabel from '../../../components/custom-inputs/BaseInputLabel';
import CustomButton from '../../../components/button/CustomButton';
import SubscribeModal from '../../../components/subscribe-to-newsletter/components/SubscribeModal';
import CustomInput from '../../../components/custom-input/CustomInput';
import { EnumPublicPagesImage } from '../../../utils/enums';
import { getPublicImageUrl } from '../../../utils/helpers';
import { LAYOUT_DEFAULTS } from '../../../utils/constants';
import { primaryLight } from '../../../utils/color';

const SubscribeToNewsletter = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErroModalOpen, setIsErrorModalOpen] = useState(false);
  const form = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
  });

  const { mutate, isLoading } = useCreate({ resource: 'newsletter/subscribe' });

  const onSubmit = () => {
    const eventHandlers = {
      onSuccess: (e: any) => {
        setIsSuccessModalOpen(true);
        form.reset();
      },
      onError: (err: any) => {
        setIsErrorModalOpen(true);
        form.reset();
      },
    };
    mutate({ email: form.getValues('email') }, eventHandlers);
  };

  return (
    <>
      <FormProvider {...form}>
        <form style={{ backgroundColor: primaryLight[100] }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              position: 'relative',
              bgcolor: 'primaryLight.100',
              // px: { xs: 7, lg: 8 },
              py: { xs: 9, lg: 5, xl: 5 },
              pb: { xs: 10, sm: 10, lg: 10 },

              width: { xs: 'auto', xl: 'auto' },
              overflow: { xs: 'hidden', lg: 'visible' },
              mt: { xs: '0px', sm: '0px', md: '0px', lg: '60px' },
              my: 8,
              ...LAYOUT_DEFAULTS,
            }}
          >
            {/* Evolve Your Business text & description text & button */}
            <Stack spacing="36px" sx={{ maxWidth: { xs: '100%', lg: '55%' } }}>
              <Stack direction="column" spacing={3} alignItems="flex-start">
                <Typography
                  variant="publicHeading3"
                  color="secondaryGray.900"
                  sx={{ fontSize: { xs: '2rem', lg: '3rem' } }}
                >
                  Stay ahead with our Spona Newsletter
                </Typography>
                <Typography
                  variant="publicBody3Regular"
                  color="secondaryGray.900"
                  sx={{ maxWidth: '718px', fontSize: { xs: '1rem', lg: '1.25rem' } }}
                >
                  Don’t miss out on expert tips, industry insights, and the latest updates from the
                  Spona community. Join our newsletter to stay informed and inspired!
                </Typography>
                <Stack
                  spacing={3}
                  direction={{ xs: 'column', md: 'row' }}
                  alignItems="flex-start"
                  justifyContent="space-between"
                  sx={{ width: '100%', maxWidth: '845px' }}
                >
                  <Stack
                    direction="column"
                    spacing={0.5}
                    alignItems="flex-start"
                    sx={{ width: '100%' }}
                  >
                    <BaseInputLabel text="Email" sx={{ mb: 0 }} />
                    <Box
                      sx={{
                        backgroundColor: '#fff',
                        width: '100%',
                        maxWidth: { xs: '718px', md: 'none' },
                      }}
                    >
                      <CustomInput
                        input={<CustomInput.Text />}
                        name="email"
                        type="email"
                        placeholder="Enter your e-mail"
                        // label="Email"
                        required
                        disabled={isLoading}
                        validationRegEx={{
                          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: 'Invalid email address',
                        }}
                        sx={{ width: '100%', backgroundColor: '#fff' }}
                      />
                    </Box>
                  </Stack>
                  <CustomButton
                    variant="primary"
                    sx={{
                      height: '2.8125rem',
                      width: '100%',
                      maxWidth: '120px',
                      marginTop: '22px !important',
                    }}
                    onClick={form.handleSubmit(onSubmit)}
                    loading={isLoading}
                  >
                    Subscribe
                  </CustomButton>
                </Stack>
              </Stack>
            </Stack>

            <Box
              alt="Mock-up of a laptop showing a screenshot of Spona’s tool "
              component="img"
              src={getPublicImageUrl(EnumPublicPagesImage.Laptop2)}
              sx={{
                display: { xs: 'none', bg: 'block' },
                // position: { sm: 'absolute', xl: 'relative' },
                // right: { xs: 10, bg: 0, lg: -85, xxl: -150 },
                width: '54%',
              }}
            />
          </Stack>
        </form>
      </FormProvider>
      <SubscribeModal
        type="success"
        isOpen={isSuccessModalOpen}
        onConfirm={() => setIsSuccessModalOpen(false)}
        setIsOpen={setIsSuccessModalOpen}
      />
      <SubscribeModal
        type="error"
        isOpen={isErroModalOpen}
        onConfirm={() => setIsErrorModalOpen(false)}
        setIsOpen={setIsErrorModalOpen}
      />
    </>
  );
};

export default SubscribeToNewsletter;
