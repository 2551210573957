import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import theme from '../../utils/theme';
import { LAYOUT_DEFAULTS } from '../../utils/constants';
import TestimonialCard from './testimonial-card/TestimonialCard';
import CustomAliceCarousel from '../custom-alice-carousel/CustomAliceCarousel';

const responsive = {
  [theme.breakpoints.values.xs]: { items: 1 },
  [theme.breakpoints.values.sm]: { items: 1 },
  [theme.breakpoints.values.md]: { items: 1 },
  [theme.breakpoints.values.lg]: { items: 1 },
  [theme.breakpoints.values.gr]: { items: 1 },
  [theme.breakpoints.values.xl]: { items: 1 },
  [theme.breakpoints.values.xxl]: { items: 1 },
};

export interface Testimonial {
  icon: string;
  subject: string;
  author: string;
  title: string;
  alt?: string;
}

const Testimonials = ({
  testimonials,
  text = 'Testimonials',
  subtext = 'Find out what members of our community have to say about their experience with Spona.',
  showbackgroundColor,
  sx,
}: {
  testimonials: Testimonial[];
  text?: string;
  subtext?: string;
  showbackgroundColor?: boolean;
  sx?: SxProps<Theme>;
}) => (
  <Stack
    py={8}
    sx={{
      backgroundColor: showbackgroundColor ? 'transparent' : 'primaryDark.200',
      position: 'relative',
      ...sx,
    }}
  >
    <Stack
      spacing={{ xs: 7, lg: 7 }}
      direction={{ xs: 'column', lg: 'row' }}
      alignItems="center"
      justifyContent="start"
      sx={{ ...LAYOUT_DEFAULTS, position: 'relative' }}
    >
      <Stack spacing={2.5} width={{ xs: '100%', lg: '100%' }} minWidth={{ lg: '420px' }}>
        <Typography
          sx={{
            typography: { xs: 'publicHeading4', lg: 'publicHeading3' },
            mr: 12,
            pb: { xs: 0, md: 1, lg: 2 },
          }}
        >
          {text}
        </Typography>

        <Typography
          maxWidth={{
            xs: '100%',
            // lg: '90%',
          }}
          variant="publicBody3Light"
          sx={{ fontSize: '1rem' }}
        >
          {subtext}
        </Typography>
      </Stack>

      <Box
        sx={{
          width: { xs: '100%', lg: '100%' },
          position: { xs: 'relative', lg: 'initial' },
        }}
      >
        <CustomAliceCarousel
          responsive={responsive}
          items={testimonials.map((testimonial) => (
            <TestimonialCard testimonial={testimonial} />
          ))}
          buttonStyles={{
            top: '50%',
            transform: 'translateY(-50%)',
            right: { xs: '-4%', md: '-1%', lg: '0%', xxl: '-15%' },
          }}
          // autoWidth
        />
      </Box>
    </Stack>
  </Stack>
);

export default Testimonials;
