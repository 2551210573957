import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, Stack } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { useMemo } from 'react';
import { useCustomInputContext } from '../CustomInputContext';
import CustomChip from '../../custom-chip/CustomChip';

// TODO: edge cases, e.g.: choices instanceof Array
// TODO: add transformLabelFn prop, etc.
const CustomInputAutocomplete = () => {
  const {
    field,
    fullWidth,
    hasError,
    placeholder,
    useFormContext,
    choices,
    multiple,
    name,
    disabled,
    valueKey,
    labelKey,
    disableClearable,
    keepOnlyValueKeys,
  } = useCustomInputContext();

  const { getValues, setValue, trigger } = useFormContext();

  const fieldValue = getValues(name);

  const memoizedChoices = useMemo(() => choices, [choices]);

  const opt = memoizedChoices
    ? choices instanceof Map
      ? Array.from(memoizedChoices.keys())
      : Array.isArray(choices) && keepOnlyValueKeys
      ? choices.map((val) => val[valueKey as string])
      : choices
    : [];

  const value = useMemo(() => (field.value === '' ? null : field.value), [field.value, choices]);

  const onChange = (event: any, newValue: any, reason: any) => {
    if (newValue === null) {
      field.onChange('');
    } else {
      field.onChange(newValue);
    }
  };

  const getOptionLabel = (option: any) =>
    Array.isArray(memoizedChoices)
      ? keepOnlyValueKeys
        ? memoizedChoices.find((item) => item[valueKey as string] === option)?.[labelKey as string]
        : option[labelKey as string]
      : memoizedChoices instanceof Map
      ? memoizedChoices.get(option)
      : '';
  const getOptionKey = (option: any) =>
    Array.isArray(memoizedChoices) && !keepOnlyValueKeys ? option[valueKey as string] : option;

  const isOptionEqualToValue = (option: any, value: any) =>
    Array.isArray(memoizedChoices) && !keepOnlyValueKeys
      ? option[valueKey as string] === value[valueKey as string]
      : option === value;

  return (
    <Stack>
      <Autocomplete
        {...field}
        // slotProps={{
        //   popper: {
        //     open: true,
        //     onScroll: (e) => {
        //       console.log(e);
        //     },
        //   },
        //   paper: {
        //     onScroll: (e) => {
        //       console.log(e);
        //     },
        //   },
        // }}
        // ListboxProps={{
        //   onScroll: (e) => {
        //     console.log(e);
        //   },
        // }}
        disableCloseOnSelect
        disableClearable={disableClearable}
        disabled={disabled}
        disablePortal
        fullWidth={fullWidth}
        id="combo-box-demo"
        multiple={multiple}
        value={value}
        onChange={onChange}
        options={opt as any}
        getOptionLabel={getOptionLabel}
        getOptionKey={getOptionKey}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={(props: any, option, state) => (
          <MenuItem
            {...props}
            key={props.key}
            value={props.key}
            sx={{ justifyContent: 'flex-start !important', alignItems: 'center' }}
          >
            {multiple && (
              <>
                <CheckBoxOutlinedIcon
                  className="MenuItemCheckedIcon"
                  sx={{ color: 'primaryLight.500', width: 22, height: 22, mr: 0.85 }}
                />

                <CheckBoxOutlineBlankOutlinedIcon
                  className="MenuItemUncheckedIcon"
                  sx={{ color: 'primaryDark.300', width: 22, height: 22, mr: 0.85 }}
                />
              </>
            )}

            {Array.isArray(memoizedChoices)
              ? keepOnlyValueKeys
                ? memoizedChoices.find((item) => item[valueKey as string] === option)?.[
                    labelKey as string
                  ]
                : option[labelKey as string]
              : memoizedChoices instanceof Map
              ? memoizedChoices.get(option)
              : option}
          </MenuItem>
        )}
        popupIcon={<ExpandMoreIcon sx={{ pointerEvents: 'none !important' }} />}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} error={hasError} variant="outlined" />
        )}
        renderTags={(option: any) => []}
      />

      {multiple && fieldValue?.length ? (
        <Stack direction="row" sx={{ flexWrap: 'wrap', gap: '10px', mt: '16px' }}>
          {fieldValue.map((val: any) => {
            const chipLabel = Array.isArray(memoizedChoices)
              ? keepOnlyValueKeys
                ? memoizedChoices.find((item) => item[valueKey as string] === val)?.[
                    labelKey as string
                  ]
                : val[labelKey as string]
              : memoizedChoices instanceof Map
              ? memoizedChoices.get(val)
              : '';

            return (
              <CustomChip
                key={val}
                label={chipLabel}
                // label={transformLabelFn ? transformLabelFn(chipLabel) : chipLabel}
                onDelete={() => {
                  setValue(
                    name,
                    fieldValue.filter((item: any) =>
                      Array.isArray(memoizedChoices) && !keepOnlyValueKeys
                        ? item[valueKey as string] !== val[valueKey as string]
                        : item !== val,
                    ),
                  );
                  trigger(name);
                }}
              />
            );
          })}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default CustomInputAutocomplete;
