import { useState } from 'react';
import { Avatar, IconButton, Stack, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import useTable from '../../../../components/table/useTable';
import CustomButton from '../../../../components/button/CustomButton';
import BaseTable from '../../../../components/table/BaseTable';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/layout-icons/PencilIcon.svg';
import { primaryDark } from '../../../../utils/color';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/chat-icons/trash2-fill.svg';
import TeamCreateAndEditModal from './components/TeamCreateAndEditModal';
import TeamDeleteModal from './components/TeamDeleteModal';
import useAuth from '../../../../utils/hooks/useAuth';

const Team = () => {
  const {
    authData: { userData: currentUser },
  } = useAuth();

  const [type, setType] = useState<'create' | 'edit'>('create');
  const [teamMemberId, setTeamMemberId] = useState<string | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [forceAvatarRemount, setForceAvatarRemount] = useState<number>(0);

  const { resourceLimit, ...props } = useTable({
    resourceUrl: 'company-team-member',
    queryParams: `company=${currentUser?.membership?.company}`,
  });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => (
        <Stack spacing={2} direction="row" alignItems="center">
          <Avatar
            key={forceAvatarRemount}
            alt="company-team-member-avatar"
            src={params.row.avatar}
          />

          <Typography>{params.value}</Typography>
        </Stack>
      ),
    },
    {
      field: 'jobTitle',
      headerName: 'Job title',
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => <Typography>{params.value}</Typography>,
    },
    {
      field: 'icons',
      headerName: '',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction="row">
          <IconButton
            onClick={() => {
              setType('edit');
              setTeamMemberId(params.row.id);
              setIsEditModalOpen(true);
            }}
          >
            <PencilIcon fill={primaryDark[500]} style={{ width: 18, height: 18 }} />
          </IconButton>

          <IconButton
            onClick={() => {
              setTeamMemberId(params.row.id);
              setIsDeleteModalOpen(true);
            }}
          >
            <TrashIcon fill={primaryDark[500]} style={{ width: 22, height: 22 }} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <>
      {isEditModalOpen && (
        <TeamCreateAndEditModal
          type={type}
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          teamMemberId={teamMemberId}
          setTeamMemberId={setTeamMemberId}
          setForceAvatarRemount={setForceAvatarRemount}
        />
      )}

      {isDeleteModalOpen && (
        <TeamDeleteModal
          isDeleteModalOpen={isDeleteModalOpen}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          teamMemberId={teamMemberId}
          setTeamMemberId={setTeamMemberId}
        />
      )}

      <BaseTable
        disableSelectionOnClick
        numberOfRowsPerPage={resourceLimit}
        columns={columns}
        withElements={{
          withTitle: 'Team members',
          withCta: (
            <CustomButton
              startIcon={<AddIcon />}
              onClick={() => {
                setType('create');
                setIsEditModalOpen(true);
              }}
            >
              Add new
            </CustomButton>
          ),
        }}
        {...props}
      />
    </>
  );
};

export default Team;
