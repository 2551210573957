import { Box, Stack, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CompanyRole,
  EnumNotificationSubcategory,
  EnumProjectMilestoneStatus,
  EnumScreenList,
  EnumTutorialStatus,
} from 'tdc-web-backend/enums/enums';
import {
  MilestoneDto,
  MilestoneSnakeDto,
  UpdateMilestoneDto,
} from 'tdc-web-backend/milestones/schemas';
import { CreateTaskDto, TaskDto } from 'tdc-web-backend/tasks/schemas';
import { useNavigate, useParams } from 'react-router-dom';
import Joyride from 'react-joyride';
import { ReactComponent as FileDownloadIcon } from '../../../../../assets/icons/project-icons/FileDownloadIcon.svg';
import CustomButton from '../../../../../components/button/CustomButton';
import ControlledTextInput from '../../../../../components/custom-inputs/ControlledTextInput';
import CustomTable from '../../../../../components/custom-table/CustomTable';
import ProgressBar from '../../../../../components/progress-bar/ProgressBar';
import StatusChipField from '../../../../../components/StatusChipField/StatusChipField';
import { EndToEndDetailScreenTypeEnum } from '../../../../../utils/enums';
import GeneralMilestoneDetails from '../GeneralMilestoneDetails/GeneralMilestoneDetails';
import { ReactComponent as FlagIcon } from '../../../../../assets/icons/layout-icons/FlagIcon.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/icons/button-icons/PlusIcon.svg';
import { ReactComponent as LinkIcon } from '../../../../../assets/icons/link-icon.svg';
import { primaryDark, secondaryBlue, secondaryPink } from '../../../../../utils/color';
import useUserCompany from '../../../../../utils/hooks/useUserCompany';
import { MilestoneActionButton } from './MilestoneActionButton';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';
import ConfirmModal from '../../../../../components/confirm-modal/ConfirmModal';
import useUpdate from '../../../../../utils/hooks/crud-hooks/useUpdate';
import InvoiceUploadModal from '../../../ProjectDetail/modals/InvoiceUploadModal';
import CopyTasksModal from '../../../../../components/copy-tasks-modal/CopyTasksModal';
import useJoyride from '../../../../../utils/hooks/joyride-hooks/useJoyride';
import { TasksJoyrideSteps } from '../../../../../utils/hooks/joyride-hooks/MilestoneDetailScreenJoyride';
import JoyrideTooltip from '../../../../../utils/hooks/joyride-hooks/JoyrideTooltip';
import NavigationDashboardContext from '../../../../../layout/dashboard-navigation/NavigationDashboardContext';
import { ReactComponent as FolderMagnifier } from '../../../../../assets/icons/layout-icons/FolderMagnifier.svg';
import BannerNotificationMapper from '../../../../../components/notifications/bannerNotifications/BannerNotificationMapper';
import { formatDateFns } from '../../../../../utils/helpers';
import CustomTooltip from '../../../../../components/tooltip/CustomTooltip';
import useAuth from '../../../../../utils/hooks/useAuth';

interface LeftColumnProps {
  showRightColumnn: boolean;
  setShowRightColumn: Dispatch<SetStateAction<boolean>>;
  milestoneData: MilestoneDto | undefined;
  milestonesArray: MilestoneSnakeDto[] | undefined;
  getRecurringMilestonesParentIndex: () => number | null;
  setIsFlagAndUnflagModalOpen: Dispatch<SetStateAction<boolean>>;
  setEntityType: Dispatch<SetStateAction<EndToEndDetailScreenTypeEnum>>;
  setEntityId: Dispatch<SetStateAction<string | null>>;
  setNumberOfRowsTasks: Dispatch<SetStateAction<number | null>>;
  tasks: TaskDto[] | null;
  setTasks: Dispatch<SetStateAction<TaskDto[] | null>>;
  taskId: string | null;
  setTaskId: Dispatch<SetStateAction<string | null>>;
  scoreMilestone: number;
  determineWhichTaskToShow: (tasks: TaskDto[]) => string | null;
  recurringMilestones: MilestoneDto[] | undefined;
  setIsTaskDateEndEditing: Dispatch<SetStateAction<boolean>>;
  rightColumnWidth: number;
  isProjectLocked?: boolean;
}

const LeftColumn = ({
  showRightColumnn,
  setShowRightColumn,
  milestoneData,
  milestonesArray,
  getRecurringMilestonesParentIndex,
  setIsFlagAndUnflagModalOpen,
  setEntityType,
  setEntityId,
  setNumberOfRowsTasks,
  tasks,
  setTasks,
  taskId,
  setTaskId,
  scoreMilestone,
  determineWhichTaskToShow,
  recurringMilestones,
  setIsTaskDateEndEditing,
  rightColumnWidth,
  isProjectLocked = false,
}: LeftColumnProps) => {
  const refresh = useRefresh();
  const userCompany = useUserCompany();
  const isUserBuyer = userCompany?.roles?.includes(CompanyRole.Buyer);
  const {
    authData: { userData: currentUser },
  } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const currentNavigationDashboardWidth = useContext(NavigationDashboardContext);

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState<boolean>(false);
  const [isCopyTasksModalOpen, setIsCopyTasksModalOpen] = useState<boolean>(false);

  const [milestoneStatusUpdate, setMilestoneStatusUpdate] =
    useState<EnumProjectMilestoneStatus | null>(null);

  const [doesPreviousRecurringMilestoneHaveTasks, setDoesPreviousRecurringMilestoneHaveTasks] =
    useState<boolean>(false);

  const getPreviousRecurringMilestone = (): MilestoneDto | undefined => {
    const previousRecurringMilestone =
      recurringMilestones?.[
        recurringMilestones.findIndex((item) => item.id === params.milestoneId) - 1
      ];

    return previousRecurringMilestone;
  };

  const addTaskformMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  const {
    handleSubmit: handleAddTaskSubmit,
    reset: addTaskReset,
    formState: { isValid },
  } = addTaskformMethods;

  const { mutate: createTask, isLoading } = useCreate<TaskDto, CreateTaskDto>({
    resource: '/tasks/',
  });

  const { mutate: update } = useUpdate<MilestoneDto, UpdateMilestoneDto>({
    resource: '/milestones',
  });

  const {
    joyride,
    runJoyride,
    tutorials,
    updateJoyride: updateJoyRide,
    update: updateJoyrideQuery,
  } = useJoyride();

  const onAddTaskSubmit = (data: Partial<TaskDto>) => {
    createTask(
      {
        milestone: params.milestoneId as string,
        description: '',
        name: data.name as string,
        start: new Date(),
        // tomorrow's date
        end: new Date(new Date().setDate(new Date().getDate() + 1)),
      },
      {
        onSuccess: ({ data }) => {
          addTaskReset();
          refresh();

          setTaskId(data.id);

          setIsTaskDateEndEditing(true);

          if (!tutorials?.includes(EnumTutorialStatus.TaskDetail)) {
            runJoyride();
            updateJoyrideQuery(EnumTutorialStatus.TaskDetail);
          }
        },
      },
    );
  };

  useEffect(() => {
    if (getPreviousRecurringMilestone() !== undefined) {
      setDoesPreviousRecurringMilestoneHaveTasks(() => {
        if (getPreviousRecurringMilestone()?.tasksCount === 0) {
          return false;
        }

        return true;
      });
    }
  }, [getPreviousRecurringMilestone]);

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.07,
      renderCell: (params: GridRenderCellParams) => <StatusChipField text={params.value} />,
    },
    {
      field: 'name',
      headerName: 'Task',
      flex: 0.4,
      renderCell: (params: GridRenderCellParams) => {
        const isTaskFlagged = params?.row?.buyerFlagged || params?.row?.sellerFlagged;
        const hasFiles = params?.row?.files?.length > 0;

        return (
          <Stack
            spacing={1.5}
            direction="row"
            alignItems="center"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            <CustomTooltip title={params.value} placement="top">
              <Typography
                variant="body2"
                fontWeight={600}
                sx={{
                  color: 'primaryDark.600',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                }}
              >
                {params.value}
              </Typography>
            </CustomTooltip>

            {isTaskFlagged && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  bgcolor: 'secondaryPink.100',
                  width: '36px',
                  height: '29px',
                  borderRadius: '6px',
                }}
              >
                <FlagIcon fill={secondaryPink[700]} style={{ width: 16, height: 16 }} />
              </Stack>
            )}
            {hasFiles && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  bgcolor: 'secondaryBlue.100',
                  width: '36px',
                  height: '29px',
                  borderRadius: '6px',
                }}
              >
                <LinkIcon fill={secondaryBlue[700]} style={{ width: 16, height: 16 }} />
              </Stack>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'end',
      headerName: 'End date',
      flex: 0.09,
      renderCell: (params: GridRenderCellParams) => {
        if (params.value !== null) {
          return <Typography fontSize="0.875rem">{formatDateFns(params.value)}</Typography>;
        }

        return <Typography>-</Typography>;
      },
    },
  ];

  return (
    <>
      {tutorials?.includes(EnumTutorialStatus.MilestoneDetail) && (
        <Joyride
          steps={TasksJoyrideSteps(isUserBuyer as boolean)}
          run={joyride.run}
          scrollDuration={400}
          scrollToFirstStep
          showSkipButton
          disableScrolling={
            joyride.steps[joyride.stepIndex] && joyride.steps[joyride.stepIndex].disableScrolling
          }
          stepIndex={joyride.stepIndex}
          callback={(e) => updateJoyRide(e)}
          continuous
          floaterProps={{
            styles: {
              floaterOpening: {
                zIndex: 999999,
              },
            },
          }}
          tooltipComponent={(props) => JoyrideTooltip(props)}
        />
      )}

      <ConfirmModal
        isModalOpen={confirmModalOpen}
        setIsModalOpen={setConfirmModalOpen}
        title={(() => {
          switch (milestoneStatusUpdate) {
            case EnumProjectMilestoneStatus.UnderReview:
              return 'Milestone review';
            case EnumProjectMilestoneStatus.Pending:
              return 'Milestone start';
            case EnumProjectMilestoneStatus.Approved:
              return 'Approve milestone';
            case EnumProjectMilestoneStatus.Completed:
              return 'Approve milestone';
            default:
              return 'Confirm action';
          }
        })()}
        message={(() => {
          switch (milestoneStatusUpdate) {
            case EnumProjectMilestoneStatus.UnderReview:
              return 'You are notifying the buyer that that the work on this milestone is ready for review.';
            case EnumProjectMilestoneStatus.Pending:
              return 'The milestone will be marked as started. Confirm to notify the buyer that the milestone can now be reviewed.';
            case EnumProjectMilestoneStatus.Approved:
              return 'You are confirming that the milestone has been reviewed and approved.';
            case EnumProjectMilestoneStatus.Completed:
              return 'This action will confirm that the milestone has been reviewed and approved.';
            default:
              return 'Confirm';
          }
        })()}
        confirmButtonText={(() => {
          switch (milestoneStatusUpdate) {
            case EnumProjectMilestoneStatus.UnderReview:
              return 'Request review';
            case EnumProjectMilestoneStatus.Approved:
              return 'Approve';
            default:
              return 'Confirm';
          }
        })()}
        onConfirm={() => {
          switch (milestoneStatusUpdate) {
            // REQUEST: milestone/id/request_review
            case EnumProjectMilestoneStatus.UnderReview:
              update(
                {
                  id: milestoneData?.id as string,
                  suffix: 'request-review',
                },
                {
                  onSuccess: () => {
                    refresh();
                  },
                },
              );
              break;

            // REQUEST: milestone/id/approve
            case EnumProjectMilestoneStatus.Approved:
              update(
                {
                  id: milestoneData?.id as string,
                  suffix: 'approve',
                },
                {
                  onSuccess: () => {
                    refresh();
                  },
                },
              );
              break;
            default:
              return null;
          }

          return null;
        }}
        iconProps={{
          wrapperBackgroundColor: secondaryBlue[100],
          icon: <FolderMagnifier fill={secondaryBlue[700]} style={{ width: 22, height: 22 }} />,
        }}
      />

      {/* render only if isInvoiceModalOpen */}
      {isInvoiceModalOpen && (
        <InvoiceUploadModal
          isOpen={isInvoiceModalOpen}
          setIsOpen={setIsInvoiceModalOpen}
          data={milestoneData as MilestoneDto}
        />
      )}

      <CopyTasksModal
        isModalOpen={isCopyTasksModalOpen}
        // isModalOpen={true}
        setIsModalOpen={setIsCopyTasksModalOpen}
        // used to set the DEFAULT value of previous recurring milestone
        previousMilestone={getPreviousRecurringMilestone()}
        recurringMilestones={
          // do not let the current milestone be in the choices for the select
          // because we don't want allow to copy tasks from same milestone to that milestone
          recurringMilestones?.filter(
            (recurringMilestone) => recurringMilestone.id !== params.milestoneId,
          ) || []
        }
        width="30%"
      />

      <Stack
        spacing={4}
        width={{ md: !showRightColumnn ? '100%' : '45%', lg: '55%' }}
        sx={{ ml: '0px !important' }}
      >
        <BannerNotificationMapper
          screen={EnumScreenList.MilestoneScreen}
          customAction={{
            action: () => setIsCopyTasksModalOpen(true),
            subcategory: EnumNotificationSubcategory.BannerRecurringMilestone,
          }}
        />
        <GeneralMilestoneDetails
          milestoneData={milestoneData}
          recurringMilestonesParent={
            milestonesArray?.[getRecurringMilestonesParentIndex() as number]
          }
          setIsFlagAndUnflagModalOpen={setIsFlagAndUnflagModalOpen}
          setEntityType={setEntityType}
          setEntityId={setEntityId}
          setTaskId={setTaskId}
          determineWhichTaskToShow={determineWhichTaskToShow}
        />

        {/* progress bar & milestone approval button */}
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <Stack
            spacing={2}
            direction="row"
            width={{
              md: milestoneData?.status === EnumProjectMilestoneStatus.Completed ? '100%' : '58%',
              xl: milestoneData?.status === EnumProjectMilestoneStatus.Completed ? '100%' : '75%',
            }}
          >
            <StatusChipField
              text={milestoneData?.status as string}
              componentReturnType="chip"
              className="react-joyride-step-4-milestone"
            />

            <ProgressBar value={Number(scoreMilestone) || 0} />
          </Stack>

          {/* button for requesting a review/marking approved/uploading an invoice etc. */}
          {(() => {
            if (milestoneData?.status === EnumProjectMilestoneStatus.Completed) {
              return <></>;
            }

            if (!isUserBuyer) {
              if (milestoneData?.status === EnumProjectMilestoneStatus.Pending) {
                return (
                  <MilestoneActionButton variant="incompleteDisabled">
                    Request review
                  </MilestoneActionButton>
                );
              }
              if (milestoneData?.status === EnumProjectMilestoneStatus.InProgress) {
                if (isProjectLocked) {
                  return (
                    <MilestoneActionButton variant="incompleteDisabled">
                      Request review
                    </MilestoneActionButton>
                  );
                }

                if (milestoneData?.tasksCount === 0) {
                  return (
                    <MilestoneActionButton variant="incompleteDisabled">
                      Request review
                    </MilestoneActionButton>
                  );
                }

                if (
                  milestoneData?.tasksCount - milestoneData?.tasksCanceledCount ===
                  milestoneData?.tasksCompletedCount
                ) {
                  return (
                    <MilestoneActionButton
                      variant="incompleteEnabled"
                      onClick={() => {
                        if (!isUserBuyer) {
                          if (milestoneData?.status === EnumProjectMilestoneStatus.InProgress) {
                            setConfirmModalOpen(true);
                            setMilestoneStatusUpdate(EnumProjectMilestoneStatus.UnderReview);
                          }
                        }
                      }}
                    >
                      Request review
                    </MilestoneActionButton>
                  );
                }

                return (
                  <MilestoneActionButton variant="incompleteDisabled">
                    Request review
                  </MilestoneActionButton>
                );
              }
              if (milestoneData?.status === EnumProjectMilestoneStatus.UnderReview) {
                return (
                  <MilestoneActionButton variant="completedDisabled">
                    Request review
                  </MilestoneActionButton>
                );
              }
              if (milestoneData?.status === EnumProjectMilestoneStatus.Approved) {
                if (milestoneData?.sellerInvoice) {
                  return (
                    <MilestoneActionButton variant="completedDisabled">
                      Request payment
                    </MilestoneActionButton>
                  );
                }
                if (isProjectLocked) {
                  return (
                    <MilestoneActionButton variant="incompleteDisabled">
                      Request payment
                    </MilestoneActionButton>
                  );
                }
                return (
                  <MilestoneActionButton
                    variant="incompleteEnabled"
                    onClick={() => {
                      setIsInvoiceModalOpen(true);
                    }}
                  >
                    Request payment
                  </MilestoneActionButton>
                );
              }
            }
            if (isUserBuyer) {
              if (
                milestoneData?.status === EnumProjectMilestoneStatus.Pending ||
                milestoneData?.status === EnumProjectMilestoneStatus.InProgress
              ) {
                return (
                  <MilestoneActionButton variant="incompleteDisabled">
                    Approve
                  </MilestoneActionButton>
                );
              }
              if (milestoneData?.status === EnumProjectMilestoneStatus.UnderReview) {
                if (isProjectLocked) {
                  return (
                    <MilestoneActionButton variant="incompleteDisabled">
                      Approve
                    </MilestoneActionButton>
                  );
                }
                return (
                  <MilestoneActionButton
                    variant="incompleteEnabled"
                    onClick={() => {
                      setConfirmModalOpen(true);
                      setMilestoneStatusUpdate(EnumProjectMilestoneStatus.Approved);
                    }}
                  >
                    Approve
                  </MilestoneActionButton>
                );
              }
              if (milestoneData?.status === EnumProjectMilestoneStatus.Approved) {
                if (milestoneData?.buyerInvoice && !isProjectLocked) {
                  return (
                    <MilestoneActionButton
                      variant="completedDisabled"
                      disabled={false}
                      startIcon={<FileDownloadIcon fill={primaryDark[600]} />}
                      href={milestoneData.buyerInvoice}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Pay milestone
                    </MilestoneActionButton>
                  );
                }

                return (
                  <MilestoneActionButton variant="completedDisabled">
                    Approved
                  </MilestoneActionButton>
                );
              }
            }
          })()}
        </Stack>

        {/* table & input */}
        <Stack spacing={2} height="100%" position="relative" justifyContent="space-between">
          {/* table containing tasks */}
          <CustomTable<TaskDto>
            resource="tasks"
            columns={columns}
            searchParams={{
              milestone: params?.milestoneId as string,
              company: currentUser?.membership?.company,
            }}
            onLoadCount={(count) => setNumberOfRowsTasks(count)}
            onDataLoaded={(tasksData) => {
              setTasks(tasksData);
              if (
                (tasks === null && tasksData.length > 0) ||
                (tasks && tasks?.length > tasksData.length)
              ) {
                setTaskId(determineWhichTaskToShow(tasksData));
              }
            }}
            selectionModel={taskId || undefined}
            onRowClick={(data) => {
              setTaskId(data.row.id);

              if (!showRightColumnn) {
                setShowRightColumn(true);
              }
            }}
            visualStyle="secondary"
            disableTabView
            sx={{ height: '400px', paddingBottom: '50px' }}
            hideFooter
          />

          {/* add new task input form */}
          <FormProvider {...addTaskformMethods}>
            <Box
              component="form"
              sx={{
                position: 'fixed',
                bottom: 15,
                zIndex: 4,
                width: {
                  md: `calc(100vw - ${currentNavigationDashboardWidth}px - ${rightColumnWidth}px - 10px)`,
                  bg: `calc(100vw - ${currentNavigationDashboardWidth}px - ${rightColumnWidth}px - 68px)`,
                },
              }}
              onSubmit={handleAddTaskSubmit(onAddTaskSubmit)}
            >
              <Stack direction="row" alignItems="center" width="100%">
                <ControlledTextInput
                  disabled={
                    milestoneData?.status === EnumProjectMilestoneStatus.UnderReview ||
                    milestoneData?.status === EnumProjectMilestoneStatus.Approved ||
                    milestoneData?.status === EnumProjectMilestoneStatus.Completed ||
                    isProjectLocked
                  }
                  required
                  placeholder="New task subject"
                  name="name"
                  variant="outlined"
                  fullWidth
                  sx={{ width: '100%', bgcolor: 'white' }}
                />

                <CustomButton
                  className="react-joyride-step-6-milestone"
                  type="submit"
                  disabled={!isValid || isLoading}
                  variant="primaryLight"
                  startIcon={<PlusIcon style={{ width: 25, height: 25 }} />}
                  sx={{ width: 160, height: '48px' }}
                >
                  <Typography variant="body3" fontWeight={600}>
                    Add Task
                  </Typography>
                </CustomButton>
              </Stack>
            </Box>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default LeftColumn;
