/* eslint-disable import/no-cycle */
import Stack from '@mui/material/Stack';
import HomepageHero from './components/HomepageHero';
import Testimonials from '../../components/public-screen-components/Testimonials';
import BecomeAPartOfSpona from '../../components/public-screen-components/BecomeAPartOfSpona';
import { testimonialsArray } from './utils';
import MetaTags from '../../components/seo/MetaTags';
import WhatIsSpona from './components/WhatIsSpona';
import FourSolutions from './components/FourSolutions/FourSolutions';
import WhyChoseSpona from './components/WhyChoseSpona/WhyChoseSpona';
import HowItWork from './components/HotItWork/HowItWork';
import NextLevel from './components/NextLevel';
import SubscribeToNewsletter from './components/SubscribeToNewsletter';

const metaTags = {
  title: `Spona - Streamline project collaboration, management, finance`,
  description: `Spona simplifies project collaboration, management, and financing, offering new clients extensive access and excellent support on a single platform.`,
};

const Homepage = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack width="100%" overflow="hidden">
      <HomepageHero />

      <WhatIsSpona />

      <FourSolutions />

      <WhyChoseSpona />

      <HowItWork />

      <BecomeAPartOfSpona
        title="Ready to start?"
        subtitle="Get Started in Minutes "
        sx={{ mt: '-100px', zIndex: 999 }}
      />

      <Testimonials
        testimonials={testimonialsArray}
        text="Success Stories"
        subtext="Find out what members of our community have to say about their experience with Spona."
        sx={{ mt: '135px' }}
      />

      <NextLevel />

      <SubscribeToNewsletter />
    </Stack>
  </>
);

export default Homepage;
