import { Typography, Stack, IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { EnumCaseStudyStatus } from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { CaseStudyContent } from 'tdc-web-backend/case-studies-content/entities';
import EditIcon from '@mui/icons-material/Edit';
import { formatDateFns, truncate } from '../../../utils/helpers';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import CustomReferenceField from '../../../components/custom-fields/CustomReferenceField';
import useTable from '../../../components/table/useTable';
import BaseTable from '../../../components/table/BaseTable';
import CustomButton from '../../../components/button/CustomButton';

const CaseStudiesListTable = () => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      renderCell: (params: GridRenderCellParams) => <StatusChipField text={params.value} />,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 0.3,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: 'primaryDark.600' }}>
          {truncate(params.value, 40)}
        </Typography>
      ),
    },
    {
      field: 'published',
      headerName: 'Published',
      flex: 0.15,
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{formatDateFns(params.value, '')}</Typography>
      ),
    },
    {
      field: 'seller',
      headerName: 'Company',
      flex: 0.2,
      renderCell: (params: GridRenderCellParams) => (
        <CustomReferenceField resource="companies" id={params.value} displayName="name" />
      ),
    },
    {
      field: 'edit',
      headerName: 'Approved',
      flex: 0.05,
      renderCell: (params: GridRenderCellParams) => (
        <CustomReferenceField
          resource="case-studies-content"
          id={params.row.edit || params.row.content}
          displayName="name"
          renderComponent={(data: CaseStudyContent) => {
            const showPending = data.status === EnumCaseStudyStatus.Pending;
            const displayStatus = showPending
              ? EnumCaseStudyStatus.Pending
              : data.status === EnumCaseStudyStatus.Draft
              ? EnumCaseStudyStatus.Draft
              : 'approved';
            return <StatusChipField text={displayStatus} componentReturnType="dot" />;
          }}
        />
      ),
    },
    {
      field: 'editIcon',
      headerName: '',
      flex: 0.03,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            // params.row.id is id of a case study
            navigate(`./edit/${params.row.id}`);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'originId',
      headerName: 'TDA ID',
      flex: 0.03,
      renderCell: (params: GridRenderCellParams) => <Typography>{params.value}</Typography>,
    },
  ];

  const panelConfig = [
    {
      title: 'All',
    },
    {
      title: 'Pending',
      search: { status: EnumCaseStudyStatus.Pending },
    },
    {
      title: 'Published',
      search: { status: EnumCaseStudyStatus.Published },
    },
    {
      title: 'Verified',
      search: { status: EnumCaseStudyStatus.Verified },
    },
    {
      title: 'Under Review',
      search: { status: EnumCaseStudyStatus.UnderReview },
    },
  ];

  const { resourceLimit, ...props } = useTable({
    resourceUrl: 'case-studies',
  });

  return (
    <Stack sx={{ height: 'fit-content', width: '100%' }} spacing={2}>
      <BaseTable
        disableSelectionOnClick
        numberOfRowsPerPage={resourceLimit}
        columns={columns}
        onRowClick={(params) =>
          params.row.edit ? navigate(`./${params.row.edit}`) : navigate(`./${params.row.content}`)
        }
        withElements={{
          withTitle: 'Case studies (tabs & filters will be added)',
          withCta: (
            <CustomButton onClick={() => navigate('./create')}>Create case study</CustomButton>
          ),
        }}
        {...props}
      />
    </Stack>
  );
};

export default CaseStudiesListTable;
